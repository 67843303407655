import {LocationInterface,resetLocation } from "./Locations.interface";
export interface AirbnbInterface{
	id: string,
	name: string,
	hostName: string,
	hostPhoneNumber: string,
	location: LocationInterface,
	address: string,
	assignedServer?: string,
	calendarUrl: string,
	calendar: Array<any>,
}

export function resetAirbn(): AirbnbInterface {
  return {
		id: "",
		name: "",
		hostName: "",
		hostPhoneNumber: "",
		location: resetLocation(),
		address: "",
		assignedServer: "",
		calendarUrl: "",
		calendar: []
  };
}