import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AirbnbClientsService } from 'src/app/services/airbnb-clients.service';
import { NbDialogService } from "@nebular/theme";
import { AirbnbInterface } from 'src/app/models/interface/Airbnb.interface';
import { ServerService } from 'src/app/services/server.service';
import { ServicioService } from 'src/app/services/servicio.service';
import { AirbnbServicesDetailsService } from 'src/app/services/airbnb-services-details.service';

@Component({
  selector: 'app-mdls-info-airbnb',
  templateUrl: './mdls-info-airbnb.component.html',
  styleUrls: ['./mdls-info-airbnb.component.scss']
})
export class MdlsInfoAirbnbComponent implements OnInit {
  @ViewChild("mdlGeneral", { static: true }) mdlGeneral: ElementRef;
  @Input() airbnb: AirbnbInterface;
  assignedServer: any = {};


  enableAssignServer: boolean;
  lista_servers: { [x: string]: any; }[];
  filteredOptionsAutocomplete: { [x: string]: any; }[];
  activeListOptionServers: boolean;
  selectedServer: any = {};

  ////////
  sourceServicesTable: any = [];
  settingServicesTable = {
    actions: {
      delete: false,
      edit: false,
      add: false,
      custom: [ { name: "viewReport", title: '<i class="nb-search"></i>' } ]
    },
    columns: {
      id:    {title: 'Id servicio'},
      fecha: { title: 'Fecha del servicio' },
      hora:  { title: 'Hora de inicio' },
      estado: { title: 'Estado' },
      server: { title: 'Server' },
      horasDeServicio: { title: 'Duracion' },
    },
    pager: {
      perPage: 15,
    },
  };
  sourceBriefingTable: { [x: string]: any; }[];
  settingsBriefingUsuario = {
    actions: {
      delete: false,
      edit: false,
      add: false,
      custom: [ /* { name: "verComprobante", title: '<i class="nb-search"></i>' } */ ]

    },
    columns: {
      damages: { title: 'Daños' },
      missingItems: { title: 'Inventario' },
      photos: { 
        title: 'Fotos',
        type: 'html',
        valuePrepareFunction: (row) => {
          // Asegúrate de que 'photos' sea un arreglo de URLs
          console.log(row)
          const result = row.map((url, index) => {
            // Devuelve un enlace para cada foto
            return `<a href="${url}" target="_blank">Foto ${index + 1}</a>`;
          }).join('<br/>'); 
          console.log(result);
          return result; // Une los enlaces con un salto de línea
        }
      }
    },
    pager: {
      display: true,
      perPage: 10
    }
  };
  serviceReport: any | null = null;

  constructor(
    private dialogService:NbDialogService,
    private airbnbService: AirbnbClientsService,
    private serversService: ServerService,
    private servicioService: ServicioService,
    private airbnbServicesDetailsService: AirbnbServicesDetailsService
  ) { }

  ngOnInit() {
    console.log(this.airbnb)
    this.openDialogSave(this.mdlGeneral);
    this.getServer();
    this.getServicies();
    this.getBriefing();
  }

  openDialogSave(dialog) {
    this.dialogService.open(dialog, {closeOnBackdropClick: false});
  }

  async getServer(){
    console.log("Server actual",this.airbnb.assignedServer);
    if(this.airbnb.assignedServer && this.airbnb.assignedServer != ""){
      this.assignedServer = (await this.serversService.detail(this.airbnb.assignedServer)).data();
    }else{
      this.assignedServer = {name: "Falta por asignar"}
    }
  }

  onEnableAssignServer(){
    this.enableAssignServer = true;
    this.serversService.Actives().then(res=>{
      console.log("Buscando servers")
      this.lista_servers = res.docs.filter(dataFlter=>dataFlter.data().habilidades.includes("aseo airbnb")).map(data=>{
        return {...data.data(),
          nombreServer: `${data.data().name} ${data.data().apellido}`
        }
      });
      this.filteredOptionsAutocomplete = JSON.parse(JSON.stringify(this.lista_servers));
      console.log(this.lista_servers)
    }).catch(error=>{
      console.log("error",error);
    })
  }

  toggleAutocompleteList(){
    this.activeListOptionServers = false;
  }

  onClickAutocomplete(event: Event,type: any): void {
    event.stopPropagation();
    this.toggleAutocompleteList();
    this.updateStateAutocomplete(type,true,"null");
  }

  onInputAutocomplete(event: any,key: any,type: string){
    let value = event.target.value ? event.target.value : ''
    console.log("val;or de onInputAutocomplete", value)
    switch (type){
      case 'nombreServer':
        this.filteredOptionsAutocomplete = this.lista_servers.filter((res: any) =>
          res[key].toLowerCase().includes(value.toLowerCase())
        );
        console.log('Lista de servers filtradas',this.filteredOptionsAutocomplete)
        break;
    }
    this.updateStateAutocomplete(type,true,null);
  }

  onSelectAutocomplete(value: any,type: string): void {
    this.updateStateAutocomplete(type,false,value);
  }

  async updateStateAutocomplete(type: string,state: boolean,value: any){
    console.log('updateStateAutocomplete',type)
    switch (type){
      case 'nombreServer':
        this.activeListOptionServers = state;
        if (value != null && value.filtroObstructor == '') {
          console.log(value);
          this.selectedServer = value;
          this.assignedServer = await this.getServerSeleccionada(value);
        } else {
          console.log('Bloqueada', value)
        }
        break;
    }
  }

  getServerSeleccionada(info) {
    return new Promise(async resolve => {
      const server = (await this.serversService.detail(info.id)).data();
      resolve(server)
    })
  }

  onSaveServer(){
    this.airbnbService.update(this.airbnb.id,{assignedServer:this.selectedServer.id})
  }

  async getServicies(){
    console.log(this.airbnb.id);
    const query = await this.servicioService.getByUser(this.airbnb.id);
    console.log("Servicios cionsiultados",query.size);
    if(query.size > 0){ 
        this.sourceServicesTable = query.docs.map(res=>{
          return {...res.data()};
        })
    }
  }
  onActionsServiceTable(event){
    console.log(event)
    switch (event.action){
      case "viewReport":
        this.getServiceReport(event.data.id)
    }
  }

  async getServiceReport(id){
    const query = await this.airbnbServicesDetailsService.detail(id)
    if(query.exists) this.serviceReport = query.data()
  }

  async getBriefing(){
    const query = await this.airbnbServicesDetailsService.getByCLient(this.airbnb.id);
    if(query.size > 0){
        this.sourceBriefingTable = query.docs.map(res=>{
          return {...res.data()};
        })
    }
  }

}
