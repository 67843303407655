import { Injectable } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFireDatabase } from 'angularfire2/database';
import { AngularFireStorage } from 'angularfire2/storage';
import * as firebase from 'firebase';
import * as FileSaver from 'file-saver';
import * as moment from 'moment';
import * as XLSX from 'xlsx';
import { ServerInterface } from '../models/interface/Server.interface';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
@Injectable({
  providedIn: 'root'
})

export class ServerService {
  constructor(
    private angularFireAuth: AngularFireAuth,
    private db: AngularFireDatabase,
    private storage: AngularFireStorage,
  ) { }

  all() {
    return firebase.firestore().collection('servers/').get();
  }

  // Actives(){
  //   return this.db.list('server/', ref => ref.orderByChild('estado').equalTo('activo')).valueChanges();
  // }
  Actives(){
    return firebase.firestore().collection('servers/')
    .where("estado","==","activo")
    .get();
  }
  actives_rt(){
    return firebase.firestore().collection('servers/')
    .where("estado","==","activo")
  }

  deleteBalanceBonos(id){
    return firebase.firestore().collection('servers/').doc(id).update({balance_bonos: firebase.firestore.FieldValue.delete()})
  }
  async getServersToCause(tipoContrato){
    const query1 = await firebase.firestore().collection('servers/')
    .where("estado","==","activo")
    .where("tipoContrato",'==',tipoContrato)
    .get();

    const date = moment().subtract(15,'days').format('YYYY-MM-DD')
    const query2 = await firebase.firestore().collection('servers/')
    .where("estado",'==','desactivado')
    .where("tipoContrato",'==',tipoContrato)
    .where("fechaDesactivacion",">=",date)
    .get();

    const a = [...query1.docs,...query2.docs]
    return a;
  }

  dePrueba(){
    return firebase.firestore().collection('servers/')
    .where("estado","==","pruebasTech")
    .get();
  }
  
  // Desactivadas(){
  //   return this.db.list('server/', ref => ref.orderByChild('estado').equalTo('desactivado')).valueChanges();
  // }
  Desactivadas(){
    return firebase.firestore().collection('servers/')
    .where("estado","==","activo")
    .get();
  }

  setHoraioServer(info){
    return firebase.firestore().collection('horariosServers/').doc(info.id).set(info)
  }


  // detail(id) {
  //   return this.db.object(`server/`).valueChanges();
  // }
  detail(id) {
    return firebase.firestore().collection('servers/').doc(`${id}`).get()
  }

  delete(id) {
    return firebase.firestore().collection('servers/').doc(`${id}`).delete()
  }

  // propertyByServer(property: string, value: any){
  //   return this.db.list('server/', ref => ref.orderByChild(property).equalTo(value)).valueChanges();
  // }
  propertyByServer(property: string, value: any){
    return firebase.firestore().collection('servers/')
    .where(`${property}`,"==",`${value}`)
    .get();
  }

  // getActivasUnicaVez(property: string, value: any){
  //   return this.db.list('server/', ref => ref.orderByChild(property).equalTo(value)).valueChanges()
  // }

  updateByServer(server: any){
    return firebase.firestore().doc('servers/'+`${server.id}`).update(server)
    .then(() => { return 'server actualizado con éxito!' })
      .catch(() => { throw 'Inténto en actualizar server, fallido!' });
  }

  updateServ(server){
    console.log(server)
    return firebase.firestore().doc('servers/' + server.id).update(server)

  }

  public putPicture(pictureName, file) {
    return this.storage.ref('server/' + pictureName).put(file);
  }

  public getURL(pictureName) {
    return this.storage.ref('server/' + pictureName).getDownloadURL();
  }

  commentary(id, messages) {
    return firebase.firestore().doc('servers/' + id).update(messages);
  }

  public guardarDiasBloqueados(data) {
    return firebase.firestore().doc('disponibilidad/' + data.idServer).set(Object.assign({}, data));
  }
  public actualizarDiasBloqueados(id,data) {
    return firebase.firestore().doc('disponibilidad/' + id).update({fechas: data});
  }

  public getDisponibilidad(id) {
    return firebase.firestore().doc('disponibilidad/' + id);
  }
  getAllDisponibilidad(){
    return firebase.firestore().collection('disponibilidad').get();
  }
  public getHorario(id) {
    return firebase.firestore().doc('horariosServers/' + id).get();
  }

  public getDisponibilidadUnaVes(id) {
    return firebase.firestore().doc('disponibilidad/' + id).get();
  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);

    Object.keys(worksheet).forEach(cell => {
      if (worksheet[cell].v && typeof worksheet[cell].v === 'string' && worksheet[cell].v.startsWith('=')) {
        worksheet[cell].f = worksheet[cell].v.slice(1); // Quitar el signo igual al asignar la fórmula
        delete worksheet[cell].v; // Eliminar el valor de texto para que solo quede la fórmula
      }
    });

    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + new Date().getTime() + EXCEL_EXTENSION);
  }


  public gurdarServerFirestore(server){
    return firebase.firestore().doc(`servers/${server.id}`).set(Object.assign({}, server))
  }
  
  public updateCalificicacion(id,calificacion){
   return firebase.firestore().doc('servers/' + id).update({calificacion: calificacion})
  }
  // public eleiminarSERVER(){
  //   return this.db.list('server/', ref => ref.orderByChild('numeroDeServicios').equalTo(0)).valueChanges()
  // }


  /****************************************************************************************/
  /*Productos servers*/
  public getProductosServers(id){
    return firebase.firestore().collection('productosServers/')
    .where('id','==',id)
    .get()
  }
  public getProductosallServers(){
    return firebase.firestore().collection('productosServers/')
    .get()
  }

  getProductosServer(id){
    return firebase.firestore().doc('productosServers/'+id).get()
  }
  public guardarProductos(info){
    return firebase.firestore().doc('productosServers/'+ info.id).set(info)
  }


  /* Apirantes */
  getAspirantes(){
    const fechaActual = Date.now();
    const unDiaEnMilisegundos = 24 * 60 * 60 * 1000;
    // const fechaRestada = new Date(fechaActual - unDiaEnMilisegundos).getTime();
    const fechaRestada = moment().subtract(1,'days').format('YYYY-MM-DD')
    
    return firebase.firestore().collection('aspirantes/')
    .where('fecha','>=',fechaRestada)
    .get()
  }
  updateAspirante(id,info){
    return firebase.firestore().collection('aspirantes').doc(id).update(info)
  }

  /* Archivos */
  getFilesServer(id){
    return firebase.firestore().collection('archivos').doc(id).get()
  }
  updateFilesServer(id,data){
    return firebase.firestore().collection('archivos').doc(id).update(data)
  }

  getAllFilesServer(id){
    return firebase.storage().ref(`/Servers/${id}/`).listAll();
  }

} 

