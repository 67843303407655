import { Injectable } from '@angular/core';
import * as firebase from 'firebase';

@Injectable({
  providedIn: 'root'
})


export class AirbnbClientsService {

  db = firebase.firestore().collection("airbnb-clients")
  constructor() { }

  get(){
    return this.db.get();
  }
  
  detail(id){
    return this.db.doc(`${id}`).get();
  }

  async add(data){
    const res = await this.db.add(data);
    res.update({id: res.id});
  }
  
  async update(id,data){
    return await this.db.doc(`${id}`).update(data);
  }


}
