export interface LocationInterface{
    lat: number,
    lng: number,
    country: string,
    department: string,
    cities: string,
    direccion: string,
    observaciones: string,
    nombreDireccion?: string,
    neighborhood?: string,
    tipoPropiedad: string,
    cities_code?: string,
    department_code?: string,
}
export function resetLocation(): LocationInterface {
  return {
    lat: 0,
    lng: 0,
    nombreDireccion: '',
    country: '',
    department: '',
    cities: '',
    direccion: '',
    observaciones: '',
    tipoPropiedad: '',
    cities_code: "",
    neighborhood: "",
    department_code: "",
  };
}


