const festivosColombia = [
    {"fecha": "2025-01-01"},
    {"fecha": "2025-01-06"},
    {"fecha": "2025-03-24"},
    {"fecha": "2025-04-17"},
    {"fecha": "2025-04-18"},
    {"fecha": "2025-05-01"},
    {"fecha": "2025-06-02"},
    {"fecha": "2025-06-23"},
    {"fecha": "2025-06-30"},
    {"fecha": "2025-07-20"},
    {"fecha": "2025-08-07"},
    {"fecha": "2025-08-18"},
    {"fecha": "2025-10-13"},
    {"fecha": "2025-11-03"},
    {"fecha": "2025-11-17"},
    {"fecha": "2025-12-08"},
    {"fecha": "2025-12-25"}
];

export{
    festivosColombia,
}
