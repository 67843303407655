import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AirbnbClientsService } from '../../services/airbnb-clients.service';
import { NbDialogService } from '@nebular/theme';
import { departamentos } from 'src/app/utility/country'
import Swal from 'sweetalert2';
import { Gps } from "src/app/models/interface/Gps.interface";
import { LocationInterface, resetLocation } from "src/app/models/interface/Locations.interface";
import { GoogleService } from 'src/app/services/google.service';
import { AirbnbInterface, resetAirbn } from 'src/app/models/interface/Airbnb.interface';

@Component({
  selector: 'app-clientes-airbnb',
  templateUrl: './clientes-airbnb.component.html',
  styleUrls: ['./clientes-airbnb.component.scss']
})
export class ClientesAirbnbComponent implements OnInit {
  @ViewChild("mdlRegisterAirbnb", { static: true }) mdlRegisterAirbnb: ElementRef;

  loading = false;
  
  airbnbsList = [];
  _airbnbsList = [];
  settingsTableAirbnbs = {
    actions: {
      delete: false,
      edit: false,
      add: false,
      filter: false,
      custom: [
        { name: "detail", title: '<i class="nb-search"></i>' },
        { name: "edit", title: '<i class="nb-compose"></i>' },
      ],
    },
    columns: {
      name: { title: "nombre" },
      departament: { title: "Departamento",
        valuePrepareFunction:(cell,row)=>{
          return row.location.department
        }
      },
      city: { title: "ciudad",
        valuePrepareFunction:(cell,row)=>{
          return row.location.cities
        }
      },
      address: { title: "Direccion" },
      hostPhoneNumber: { title:"Telefono"}
    },
  };

  // For modal register/edit airbnb
  typeOfModal = '';
  infoAirbnb: AirbnbInterface;
  departmentsList = departamentos;
  citiesList = [];
  addressPredictions: any = [];
  addressSought: any = "";
  location: LocationInterface;

  // For modal view airbnb information 
  view_airbnb_information: boolean = false;
  airbnbDisplayed: any;
  constructor(
    private googleService: GoogleService,
    private dialogService: NbDialogService,
    private airbnbService: AirbnbClientsService,
  ) { }

  ngOnInit() {
    console.log(this.departmentsList)
    this.onLoad();
  }

  onLoad(){
    this.getClients();
    this.getPlace();
  }

  async getClients(){
    this.loading = true
    const query = await this.airbnbService.get();
    const clients = query.docs.map(data=>{
      return {...data.data()};
    })
    this.airbnbsList = clients;
    this._airbnbsList = clients;
    this.loading = false;
  }

  onOpenModalRegisterAirbnb(){
    this.typeOfModal = "register";
    this.infoAirbnb = resetAirbn();
    this.location = resetLocation();
    this.addressSought = "";
    // @ts-ignore
    this.dialogService.open(this.mdlRegisterAirbnb, {closeOnBackdropClick: false});
  }

  onSelectLocation(event,type){
    let value = event.target.value;
    switch (type) {
      case "department":
        const index = this.departmentsList.findIndex(x=> x.name == value);
        this.citiesList = this.departmentsList[index].ciudades;
      break;
    }
  }

  onSearchAddress() {
    const text: string = this.addressSought;
    const city: string = this.location.cities;
    const status: boolean = city.includes("D.C.");
    if (text === "") {
      return;
    }
    const search = `${ status ? city.replace("D.C.", "") : city } ${text}, Colombia`;
    this.googleService.getPlacePredictions(search);
  }

  getPlace() {
    this.googleService.currentData.subscribe((place) => {
      this.addressPredictions = place;
    });
  }
  
  async onSelectAddress(place) {
    console.log(place);
    const gps = await this.googleService._getGpsPlace(place.description)
    this.location.lat = gps.lat;
    this.location.lng = gps.lng;
    this.location.direccion = place.description;
    this.infoAirbnb.address = place.description
    console.log("La variable addressSought",this.addressSought); 
  }
 

  onSaveNewAirbnb(){
    console.log(this.infoAirbnb)
    this.infoAirbnb.location = this.location;
    console.log(this.infoAirbnb)
    if(this.addressSought == ""){
      Swal.fire("Espera","Completa todos los campos","warning")
      return;
    }else if(this.location.lat == 0 || this.location.lng == 0){
      Swal.fire("Espera","Por favor, selecciona una dirección válida para completar la ubicación.","warning")
      return;
    }
    this.loading = true;
    console.log(this.infoAirbnb)
    this.airbnbService.add(this.infoAirbnb).then(()=>{
      Swal.fire('Listo','','success');
      this._airbnbsList.push(this.infoAirbnb);
      this.airbnbsList = this._airbnbsList;
      const a = document.getElementById("button_close_modal") as HTMLButtonElement;
      a.click();
    }).catch(error=>{
      console.log("Error adding document",error);
    }).finally(()=>{
      this.loading = false;
    })
  }
  
  onUpdateAirbnb(){
    this.loading = true;
    this.location.direccion =  this.infoAirbnb.address == "" ? this.addressSought : this.infoAirbnb.address;
    this.infoAirbnb.address = this.location.direccion;
    this.airbnbService.update(this.infoAirbnb.id,this.infoAirbnb).then(()=>{
      Swal.fire('Listo','','success');
      const a = document.getElementById("button_close_modal") as HTMLButtonElement;
      a.click();
    }).catch(error=>{
      console.log("Error updationg document",error);
    }).finally(()=>{
      this.loading = false;
    })
  }

  onActionsTable(event){
    console.log(event)
    switch (event.action){
      case 'detail':
        this.onViewAirbnbInfo(event.data)
      break;
      case 'edit':
        this.onOpenEditAirbnb(event.data);
      break;
    }
  }

  onOpenEditAirbnb(data){
    this.typeOfModal = "edit";
    this.infoAirbnb = data;
    this.addressSought = this.infoAirbnb.address;
    this.location = this.infoAirbnb.location ? this.infoAirbnb.location : resetLocation();
    // @ts-ignore
    this.dialogService.open(this.mdlRegisterAirbnb, {closeOnBackdropClick: false});
  }

  onViewAirbnbInfo(data) {
    this.airbnbDisplayed = data;
    this.view_airbnb_information = false;
    setTimeout(() => {
      this.view_airbnb_information = true;  
    }, 500);
    // this.router.navigate(["dashboard/ver-server", id_server]);
  }
}
