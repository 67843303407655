import { Component, OnInit, ViewChild, ElementRef, NgModule } from "@angular/core";

/*_________SERVICIOS__________ */
import * as firebase from 'firebase';
import { CuponService } from '../../services/cupon.service';
import { AuthService } from "src/app/services/auth.service";
import { Metricas } from "src/app/services/metricas.service";
import { GoogleService } from 'src/app/services/google.service';
import { ServerService } from "src/app/services/server.service";
import { UsuarioService } from "src/app/services/usuario.service";
import { ServicioService } from "src/app/services/servicio.service";
import { PlanRecurrenteService } from "src/app/services/plan-recurrente.service";
/*******************************/

/*__________UTILIDADES_________*/
import * as _ from "lodash";
import { Subject } from "rxjs";
import * as moment from "moment";
import * as firebase2 from "firebase";
import { Helper } from "src/app/utility/helper";
import Swal, { SweetAlertType } from "sweetalert2";
import { IMyDateRangeModel } from "mydaterangepicker";
import { Novedades } from "src/app/utility/novedades";
import { departamentos } from "src/app/utility/country";
import { ListColorService } from "src/app/utility/dataInfo";
import { OptionsColor } from "src/app/utility/OptionsColor";
import { PropertiesTypeList } from 'src/app/utility/dataInfo';
import { serviciosPrestados } from "src/app/utility/SERVicios";
import { NbToastrService, NbDialogService } from "@nebular/theme";
import { festivosColombia } from "src/app/utility/festivosColombia";
import { funcionesUsuario } from "src/app/services/funcionesUsuario";
import { preciosDesinfeccion} from "src/app/utility/precios";

import { MatPaginator, MatTableDataSource, MatSort } from '@angular/material';
import { ServBackendService } from "src/app/services/serv-backend.service";
import { PriceService } from "src/app/services/prices.service";
import { CodigoReferidoServerService } from "src/app/services/codigo-referido-server.service";
import { PlanService } from "src/app/services/plan.service";
import { AirbnbClientsService } from "src/app/services/airbnb-clients.service";
/*******************************/

@Component({
  selector: "app-servicios",
  templateUrl: "./nuevoServicios.component.html",
  styleUrls: ["./servicios.component.scss"],
})
export class NuevoServiciosComponent implements OnInit {

  @ViewChild("mdlCrear", { static: true }) mdlCrear: ElementRef;

  @ViewChild('paginator', { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  selecte_table = 'orden';
  tableDisplayedColumns = {
    column: ['actions', 'estado', 'tipoServicio', 'nombreCliente', 'nombreServer', 'fecha', 'hora', 'horasDeServicio', 'direccion', 'servicio','creado'],
    title: ['Acciones', 'Estado', 'Tipo de servicio', 'Cliente', 'Server', 'Fecha', 'Hora', 'Duracion', 'Direccion', 'Tipo de servicio','Creado']
  }

  tableDataSource: MatTableDataSource<any>;

  generalLoader: boolean = false;
  servicioVisualizado = {};
  
  verMdlInfoSevicio = false; //Activa el conponete mdls-info-servicio
  listColorService: Array<OptionsColor> = ListColorService;

  usuarioEnSecion: any;
  lugarBusqueda: string = "Todos"
  today = moment().format("YYYY-MM-DD");

  arrayServicios = []

  arrayServiciosOrdenes = [];
  arrayServiciosAceptados = [];
  arrayServiciosEnProceso = [];
  arrayServiciosPendientes = [];
  arrayServiciosTerminados = [];
  arrayServiciosCancelados = [];
  arrayServciosParaPrestar = [];
  arrayServiciosConfirmados = [];

  /*****************VARIABLES FORMULARIO CREAR SERVIVCIO****************/
  arrayFestivos = festivosColombia;
  checkFiltroSeguridadSocial = false
  filteredOptions = []; // array donde se aloja los datos mostrada en los autocomplete

  listaClientes = [];
  activeListOptionsCliente: boolean = false;
  clienteServicio: any = { nombreCliente: '' };

  listaServers = [];
  copiaListaServers = [];
  activeListOptionServers = false;
  serverSeleccionada: any = {};

  tipoCliente: string = "";
  tipoGestion: string = "";
  serviciosPrestados: any = ['Hogares','Empresas','Airbnb'];
  arrayCategoriaServicio = serviciosPrestados;
  arraySubCategoriaServicio: any;
  validoParaPlan: boolean = false;

  posiblePlan = 'no';
  requiereSegSoc = 'no';
  aceptaRemplazos = 'no';
  horasServicios = [2,3,4,6,8];
  horasDeInicio = [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];
  allPriceList = [];
  allPriceListRef = [];
  priceList = [];

  asignarPostVenta: boolean = false;

  public frecuenciasDesinfeccion: any = preciosDesinfeccion;
  public areasDesinfeccion: any;

  editarRecargoPlan: boolean = false;
  cuponElegido: any = '';
  plazoPago: string;

  /*-----Agregar de direccion durante el agendamiento-----*/
  agregarDireccion: boolean = false
  arrayDepartamentos: any = departamentos
  tipoPropiedades: any = PropertiesTypeList;
  placePredictions: any;
  arrayCiudades: any;
  nuevaDireccion = {
    country: "Colombia",
    nombreDireccion: "Mi casa",
    department: "",
    cities: "",
    neighborhood: "",
    direccion: "",
    observaciones: "",
    lat: 0,
    lng: 0,
    tipoPropiedad: "",
    idLocation: "",
  }
  /*------------------------------------------------*/

  /*----comentarios cliente---*/
  comentario = {
    name: "",
    comentario: "",
    fecha: this.today,
  };
  comentarioArray: any = [];
  ingresarComentarioLogistica: boolean = false;
  ingresarComentarioServer: boolean = false;
  /*))))))))))))))(((((((((((((*/

  /* Tareas */

  clientTaskList = [];
  activeAddNewTask: boolean = false;
  arrayDirationTask = [
    {name: '30 minutos', value: 30},
    {name: '60 minutos', value: 60},
    {name: '90 minutos', value: 90},
    {name: '120 minutos', value: 120},
  ]
  newTask = {
    name: '',
    duration: 0,
    description:'',
  }
  taskHours = 0;

  daySelectedTaskList = 0;

  /* Productos */
  totalValueExtras = 0;
  productList = [];
  activeAddNewProduct: boolean = false;
  newProduct = {
    name: '',
    value: 0,
  };
  

   /*  referidos  */
  usarCodigoReferido = false;
  codigoReferido = '';
  idCodigoReferido = '';

  tipoDeDescuento: string = "porcentual";

  /*-------variables que se guardan en el servicio--------*/
  public taskListFinal: any = [];
  public direccionServicio: any|null = null;
  public indexDireccionSeleccionada: number|null = null;
  public tipoServicio: string = "";
  public categoriaServicio: string = "";
  public subCategoriaServicio: string = "";
  public tipoAgendamiento: string = "falta";
  public horasDeServicio = 0;
  public fechaServicio: string = "";
  public diaServicio: string = "";
  public horaInicioServicio: string = "";
  public horarioServicio: string = "";
  public serverServicio: any;
  public estadoServicio: "Pendiente"

  public metrosDesinfeccionServicio = 0;

  public plazoPagoServicio: string = "";
  public metodoPagoServicio: string = "";
  public numFacturaServicio: string = "";
  public estadoDelPagoServicio: string = "";
  public referenciaPagoServicio: string = "";
  public urlComprobanteServicio: string = "";
  public fechaLimitePagoServicio: string = "";
  public pagoDosCuotas = 'no';
  public gananciaServerServicio: number = 0;
  public precioServicio: number = 0;
  public recargoServicio: number = 0;
  public recargoTransporteServicio: number = 0;
  public descuentoServicio: number = 0;
  public final_service_price: number = 0;
  public razonRecargo: string = 'Sin recargo';
  verCupones: boolean = false;
  cuponesDisponibles: any = [];
  /*-----------------------------------------------------*/

  /*__________ VARIABLES DE PLAN ___________*/
  public arrayPreInfoServiciosPlan: any = [];
  public flippedPlanes: boolean = false;
  public precioPlan: number = 0;
  public final_plan_price = 0;
  public recargoPlan: number = 0;
  public recargoTransportePlan: number = 0;
  public descuentoPlan: number = 0;
  public precioPorServicio: number = 0;

  public contadorServersDisponibles = 0
  public loadingAgendar: boolean = false
  public loaderAgendandoPlan: boolean = false
  public contadorFechasServiciosPlanVerificadas = 0
  public fechaPostVenta: string = ""
  public loaderAgendarPlan: boolean = false
  infoValoresPrecio: any;


  /**********************************************************************/

  constructor(
    private helper: Helper,
    private metricasService: Metricas,
    private authService: AuthService,
    private cuponService: CuponService,
    private priceService: PriceService,
    private googleService: GoogleService,
    private serverService: ServerService,
    private usuarioService: UsuarioService,
    private servicioService: ServicioService,
    private nbDialogService: NbDialogService,
    private funcionesUsuario: funcionesUsuario,
    private servBackendService: ServBackendService,
    private planRecurrenteService: PlanRecurrenteService,
    private airbnbClientsService: AirbnbClientsService,
    private planService: PlanService,
    private codigoReferidoServerService: CodigoReferidoServerService
  ) {
    this.usuarioEnSecion = this.authService.storeGetUserData("usuario");
  }

  ngOnInit() {
    this.lugarBusqueda = this.usuarioEnSecion.department == 'Antioquia' ? 'Medellin' : 'Bogota';
    /* Se usan en caso de querer asignar una dirteccion */
    this.getPlace();
    this.getGps();
    /****************************************************/
  }

  onSelectTipoCliente(){
    if(this.tipoCliente == "serv"){
      this.getUsers();
    }else{
      this.getAirbnbs();
    }
  }
  async getUsers() {
    console.log(this.listaClientes.length == 0);
    if(this.listaClientes.length == 0){
      this.usuarioService.getUsersByProperty("estado", "activo").then((res) => {
        this.listaClientes = res.docs.map(data => {
          const user = data.data();
          user.nombreCliente = `${user.name} ${user.apellido}`
          return {...user};
        })
        this.filteredOptions = this.listaClientes;
        console.log('clientes termino de consultar');
      });
    }
  }
  async getAirbnbs() {
    console.log(this.listaClientes.length == 0);
    if(this.listaClientes.length == 0){
      this.airbnbClientsService.get().then((res) => {
        console.log("airbnbs consultados",res.size)
        this.listaClientes = res.docs.map(data => {
          const airbnb = data.data();
          airbnb.nombreCliente = `${airbnb.name}`
          return {...airbnb};
        })
        this.filteredOptions = this.listaClientes;
        console.log('clientes termino de consultar');
      });
    }
  }

  rangoFechasGetServicios(event: IMyDateRangeModel) {
    console.log(event.endJsDate)
    console.log(new Date(event.endJsDate))
    new Date((event.beginJsDate))
    const endDate = moment(event.endJsDate).format("YYYY-MM-DD");
    const beginDate = moment(event.beginJsDate).format("YYYY-MM-DD");
    if (beginDate != "Invalid date" && endDate != "Invalid date") {
      this.getServiciosRangoFecha(beginDate, endDate);
    }
  }

  cambiarLugarDeBusqueda() {
    if (this.lugarBusqueda == "Bogota") {
      this.filtroZonaServicios('ciudad', 'Bogota', 'Bogotá', 'Cundinamarca')
    } else if (this.lugarBusqueda == "Medellin") {
      this.filtroZonaServicios('ciudad', 'Medellin', 'Medellín', 'Antioquia')
    } else if (this.lugarBusqueda == 'Sabana') {
      this.filtroZonaServicios('ciudad', 'Chía', 'Mosquera', 'Cota')
    } else {
      this.filtroZonaServicios('Todos', '', '', '')
    }
  }

  allServices = [];
  allExpresServices = [];
  async getServiciosRangoFecha(fchInicio, fchFin) {
    this.generalLoader = true;
    this.allServices = []
    console.log(fchInicio, fchFin)
    let query = this.servicioService.getServiciosRangoFechasPaginado(fchInicio, fchFin)
    while (true) {
      const snapshot = await query.get();
      if (snapshot.empty) {
        // No hay mas datos, salir del bucle
        break;
      }
      console.log(snapshot.size)
      snapshot.docs.forEach(data => {
        const servicio = data.data();
        this.allServices.push(servicio)
        if(servicio.tipoServicio == "Plan Individual") this.allExpresServices.push(servicio);
      })
      
      if (snapshot.size < 100) {
        // No hay mas datos, salir del bucle
        break;
      }
      const lastDocument = snapshot.docs[snapshot.docs.length - 1];
      query = query.startAfter(lastDocument);
    }
    
    this.generalLoader = false;

    if (this.lugarBusqueda == 'Todos') {
      this.filtroZonaServicios('Todos', '', '', '')
    } else if ( this.lugarBusqueda != 'Todos') {
      this.cambiarLugarDeBusqueda();
    }
  }

  id_servicios = [];
  filtroZonaServicios(tipo, ref1, ref2, ref3) {
    console.log('Entore en filtroZonaServicios')
    this.arrayServicios = [];
    this.arrayServiciosOrdenes = [];
    this.arrayServiciosAceptados = [];
    this.arrayServiciosEnProceso = [];
    this.arrayServiciosPendientes = [];
    this.arrayServiciosTerminados = [];
    this.arrayServiciosCancelados = [];
    this.arrayServciosParaPrestar = [];
    this.arrayServiciosConfirmados = [];
    this.allServices.forEach((servicio, index) => {
      let valido = false
      if (tipo == 'ciudad') {
        if (this.lugarBusqueda == "Sabana" && (servicio.direccion.includes("Zipaquirá") || servicio.direccion.includes("Sopó") || servicio.direccion.includes("Cajicá") || servicio.direccion.includes("La Calera") || servicio.direccion.includes("Chía") || servicio.direccion.includes("Cota") || servicio.direccion.includes("Funza") || servicio.direccion.includes("Mosquera"))) {
          valido = true
          this.id_servicios.push(servicio.id)
        } else if (this.lugarBusqueda == "Medellin" && (servicio.direccion.includes(ref1) || servicio.direccion.includes(ref2) || servicio.direccion.includes(ref3) || servicio.destination.department == "Antioquia" ) ) {
          valido = true;
          this.id_servicios.push(servicio.id)
        }else if (servicio.direccion.includes(ref1) || servicio.direccion.includes(ref2) /* || servicio.direccion.includes(ref3) || servicio.destination.department  */ ) {
          valido = true;
          this.id_servicios.push(servicio.id)
        }
      } else {
        this.id_servicios.push(servicio.id)
        valido = true;
      }

      if (valido == true) {
        servicio.nombreServer = servicio.server ? servicio.server.name + " " + servicio.server.apellido : "Sin server definida";
        servicio.nombreCliente = servicio.client ? servicio.client.name + " " + servicio.client.apellido : "Sin cliente definido";
        servicio.conComprobante = servicio.comprobante == "falta" ? "falta" : "Con comprobante";
        this.arrayServicios.push(servicio)
        
        if(servicio.estado != "Cancelado"  && servicio.estado != "Congelado" && servicio.estado != "Suspendido" && servicio.estado != "Orden de servicio" && servicio.estado != "Orden de plan"  && servicio.estado != "Orden de Servicio")
        {this.arrayServciosParaPrestar.push(servicio)}

        if (servicio.estado == "Orden de servicio" || servicio.estado == "Orden de plan" || servicio.estado == "Orden de Servicio") {
          this.arrayServiciosOrdenes.push(servicio)
        } else if (servicio.estado == "Pendiente") {
          this.arrayServiciosPendientes.push(servicio)
        } else if (servicio.estado == "Aceptado") {
          this.arrayServiciosAceptados.push(servicio)
        } else if (servicio.estado == 'Confirmado') {
          this.arrayServiciosConfirmados.push(servicio)
        } else if (servicio.estado == "En curso" || servicio.estado == "En camino") {
          this.arrayServiciosEnProceso.push(servicio)
        } else if (servicio.estado == "Terminado" && servicio.tipoServicio != 'Aministrativo') {
          this.arrayServiciosTerminados.push(servicio)
        } else if (servicio.estado == "Cancelado") {
          this.arrayServiciosCancelados.push(servicio)
        }
      }
      // console.log(this.allServices.length,index-1)
      if (index == this.allServices.length - 1) {
        this.tableDataSource = new MatTableDataSource(this.arrayServicios)
        this.tableDataSource.paginator = this.paginator
        this.tableDataSource.sort = this.sort;
        this.generalLoader = false;
      }
    })
  }

  detectarServicioSinDireccion() {
    console.log(this.id_servicios)
    const contador = {};
    this.id_servicios.forEach(elemento => {
      contador[elemento] = (contador[elemento] || 0) + 1;
    });
    // Filtrar los elementos sin duplicados
    const elementosSinDuplicados = this.id_servicios.filter(elemento => contador[elemento] === 1);
    console.log(elementosSinDuplicados);
  }

  async abrirModal(tipo) {
    let modal: any;
    if (tipo == "crearServicio") {
      // this.getUsers();
      await this.calcularMesesSiguentes();
      this.borrarFormulario()
      modal = this.mdlCrear;
      this.nbDialogService.open(modal, { context: "this is some additional data passed to dialog" });
    }
  }

  /******************FUNCIONES FORMULARIO CREAR SERVICIO***********************/
  borrarFormulario() {
    this.clienteServicio = { nombreCliente: ''}
    this.direccionServicio = null;
    this.indexDireccionSeleccionada = null;
    this.tipoGestion = ""
    this.validoParaPlan = false;
    this.tipoServicio = "";
    this.categoriaServicio = "";
    this.subCategoriaServicio = "";
    this.taskListFinal = [];
    this.taskHours = 0;
    this.tipoAgendamiento = "flata";
    this.horasDeServicio = 0;
    this.fechaServicio = "";
    this.diaServicio = "";
    this.horaInicioServicio = "";
    this.horarioServicio = "";
    this.serverServicio = null
  
    this.plazoPago = "";
    this.plazoPagoServicio = "";
    this.metodoPagoServicio = "";
    this.numFacturaServicio = "";
    this.estadoDelPagoServicio = "";
    this.referenciaPagoServicio = "";
    this.urlComprobanteServicio = "";
    this.fechaLimitePagoServicio = "";
    this.gananciaServerServicio = 0;
    this.precioServicio = 0;
    this.recargoServicio = 0;
    this.recargoTransporteServicio = 0;
    this.arrayPreInfoServiciosPlan = [];
    this.flippedPlanes = false;
    this.precioPlan = 0;
    this.final_plan_price = 0;
    this.recargoPlan = 0;
    this.recargoTransportePlan = 0;
    this.precioPorServicio = 0;
    this.contadorServersDisponibles = 0;
    this.loadingAgendar = false;
    this.contadorFechasServiciosPlanVerificadas = 0;
    this.fechaPostVenta = "";
    this.clientTaskList = [];
    this.codigoReferido = "";
    this.idCodigoReferido = "";
  }

  elegirDireccion() {
    console.log(this.indexDireccionSeleccionada);
    this.direccionServicio = this.clienteServicio.direccionesArray[this.indexDireccionSeleccionada];
    console.log(this.direccionServicio);
    setTimeout(() => {
      (<HTMLInputElement>document.getElementById("elegirGestion")).disabled = false;
    }, 100)
  }

  /*--------Funciones agregar nueva direccion --------*/
  getPlace() {
    this.googleService.currentData.subscribe((place) => {
      this.placePredictions = place;
    })
  }
  getGps() {
    this.googleService.currentGps.subscribe((origin) => {
      this.nuevaDireccion.lat = (origin != null && origin.lat != null && origin.lng != null) ? origin.lat : 0;
      this.nuevaDireccion.lng = (origin != null && origin.lat != null && origin.lng != null) ? origin.lng : 0;
    })
  }
  buscarCiudades(event) {
    let value = event.target.value;
    console.log(value, event)
    this.nuevaDireccion.idLocation = value == "Cundinamarca" ? "4fZC8McSlOvpPfIZnG7w" : "4fZC8McSlOvpPfIZnG7w"
    let index = this.arrayDepartamentos.findIndex(data => data.name == value);
    console.log(index);
    this.arrayCiudades = this.arrayDepartamentos[index].ciudades;
  }
  buscarDireccion(_term: string, _city: string) {
    const text: string = _term;
    const city: string = _city;
    const status: boolean = city.includes('D.C.');
    if (text === '') { return; }
    const search = `${status ? city.replace("D.C.", '') : city} ${text}, Colombia`;
    this.googleService.getPlacePredictions(search);
  }
  seleccionarDireccion(place) {
    this.googleService.getGpsPlace(place.description);
    this.nuevaDireccion.direccion = place.description;
  }

  guardarDireccion() {
    console.log(this.nuevaDireccion)
    this.clienteServicio.direccionesArray.push(this.nuevaDireccion)
    this.direccionServicio = this.nuevaDireccion;
    this.agregarDireccion = !this.agregarDireccion;
    this.nuevaDireccion = {
      country: "Colombia",
      nombreDireccion: "Mi casa",
      department: "",
      cities: "",
      neighborhood: "",
      direccion: "",
      observaciones: "",
      lat: 0,
      lng: 0,
      tipoPropiedad: "",
      idLocation: "",
    }
    this.usuarioService.updateClient(this.clienteServicio)
  }
  /*------------------------------------------------*/

  /*))))))))Funciones para elegir servicio((((((((*/
  async elegirServicio(event, type) {
    const name: string = event.target.value;
    let filter = null;
    // filter = await this.serviciosPrestados.filter((tipoServicio) => tipoServicio.name == name)[0];
    // this.arrayCategoriaServicio = filter[type];
    // this.categoriaServicio = "";
    // this.subCategoriaServicio = "";
    if (type == "categoria") {
      filter = await this.arrayCategoriaServicio.filter((subCategoria) => subCategoria.name == name)[0];
      this.arraySubCategoriaServicio = filter[type];
      console.log(this.arraySubCategoriaServicio);
      this.subCategoriaServicio = "";
    } 
    else if (type == "subCategoria") {
      console.log(this.subCategoriaServicio);
      const query_service = await this.priceService.getspecificField('servicio',this.subCategoriaServicio);
      const servicioSeleccionado = query_service.docs[0].data();
      const query_prices = await query_service.docs[0].ref.collection('valores').get();
      this.allPriceList = query_prices.docs.map(res=>{
        return {
          id: res.id,
          ref: res.ref,
          ...res.data()
        };
      });
      // this.allPriceListRef =  query_prices.docs.map(res=>{
      //   return {...res.ref}
      // });
      if(servicioSeleccionado.agendamiento.includes('plan mensual')) this.validoParaPlan = true;
      console.log(servicioSeleccionado);
    }
  }
  /*))))))))))))))))))))))))(((((((((((((((((((((((*/

  /*/////Funciones para el precio del servicio////////*/
  async valorarTipoAgendamiento(event) {
    let name: string = event.target.value;
    let typeForPriceQuery = name == "Plan Mensual" ? 'plan mensual' : 'expres'
    this.plazoPagoServicio = '';
    this.urlComprobanteServicio = '';
    this.fechaLimitePagoServicio = '';
    this.numFacturaServicio = '';
    this.estadoDelPagoServicio = '';
    this.plazoPago = '';

    console.log('Todos los precios',this.allPriceList);
    this.priceList = this.allPriceList.filter(res=>res.tipoAgendamiento == typeForPriceQuery);
    console.log('precios filtrados',this.priceList)
    if (name == 'Administrativo') {
      this.metodoPagoServicio = 'Nequi';
      this.plazoPagoServicio = "pago adelantado";
      this.urlComprobanteServicio = 'Pago';
      this.plazoPago = "pago adelantado";
      this.fechaLimitePagoServicio = moment().format("YYYY-MM-DD");
      this.numFacturaServicio = 'serv';
      this.estadoDelPagoServicio = "Pago";
      name = "Administrativo"
      // this.valorarPrecioPorHoras(name, 'horas')
    } else if ((name == "Plan Individual" || name == 'Multiples servicios') && this.categoriaServicio != 'Desinfección' && this.categoriaServicio != 'Limpieza especializada') {
      console.log("Entro en el if");
      // this.valorarPrecioPorHoras('Unitario', 'horas')
    } else if ((name == "Plan Individual" || name == 'Multiples servicios') && this.categoriaServicio == 'Desinfección') {
      name = "Unitario"
      this.valorPrecioDesinfeccion(name, "area")
    } else {
      console.log(name, this.categoriaServicio)
    }
  }

  elegirHora(tipo,i) {

    let hora = parseInt(this.horaInicioServicio);
    if (tipo == "porHoras") {
      if (hora <= 14)  this.horarioServicio = "mañana";
      else if (hora > 14)  this.horarioServicio = "tarde";
  
    } else if (tipo == "porHorasPlan") {
      if (hora <= 14)  this.arrayPreInfoServiciosPlan[i].horario = "mañana";
      else if (hora > 14) this.arrayPreInfoServiciosPlan[i].horario = "tarde";
    }
    this.getPrice();
  }

  async getPrice(){
    try{
      console.log(this.priceList,this.horasDeServicio);
      this.infoValoresPrecio = this.priceList.filter(res=>res.horas == this.horasDeServicio)[0];
      console.log('Info valores precio ----',this.infoValoresPrecio);
      this.precioServicio = parseInt(this.infoValoresPrecio.precio);
      this.precioPorServicio = parseInt(this.infoValoresPrecio.precio);
      this.gananciaServerServicio = this.infoValoresPrecio.gananciaServer;
      if(this.tipoAgendamiento != 'Plan Mensual') this.calcularprecioIndividual();
    }catch(error){
      console.error(error)
    }
  }

  calcularprecioIndividual(){
    let descuento = this.tipoDeDescuento == 'porcentual' ? ((this.precioServicio/100)*this.descuentoServicio) : this.descuentoServicio;
    let descuetnoAdicional = 0; // Descueto que se aplica si el serviucioo individual es en la tarde 4horas o si es para el mismo dia
    if(this.today == this.fechaServicio) descuetnoAdicional = this.infoValoresPrecio.descuentoTardes;
    else if(this.horaInicioServicio  > "2" && this.horasDeServicio == 4) descuetnoAdicional = this.infoValoresPrecio.descuentoTardes;
    this.final_service_price =  ((this.precioServicio + this.recargoServicio + this.recargoTransporteServicio - descuento) + this.totalValueExtras) - descuetnoAdicional;
  }

  elegirDuracion() {
    this.fechaServicio = '';
    this.listaServers = [];
    this.horaInicioServicio = "";
  }

  elegirFecha() {
    this.listaServers = [];
    this.horaInicioServicio = "";
    let filterFestivos = this.arrayFestivos.filter(res => res.fecha == moment(this.fechaServicio).format("YYYY-MM-DD"));
    if (filterFestivos.length > 0) {
      this.recargoServicio = 15000;
    }
    let date: any;
    let dow: any;

    date = moment(this.fechaServicio);
    dow = date.day();
  
    console.log("Dia seleccionado", dow);
    if (dow == 0) {
      this.recargoServicio += 10000;
      this.diaServicio = "domingo";
    } else if (dow == 1) {
      this.diaServicio = "lunes";
    } else if (dow == 2) {
      this.diaServicio = "martes";
    } else if (dow == 3) {
      this.diaServicio = "miercoles";
    } else if (dow == 4) {
      this.diaServicio = "jueves";
    } else if (dow == 5) {
      this.diaServicio = "viernes";
    } else if (dow == 6) {
      this.diaServicio = "sabado";
    }
    this.razonRecargo = 'Recargo dominical de 10000';
  }

  validarCodigoReferido(){
    this.codigoReferidoServerService.getByCode(this.codigoReferido).then(async res=>{
      if(res.size == 0) Swal.fire('Upps..','El codigo ingresado no es valido','warning');
      else{
        let filter = await this.codigoReferidoServerService.detailInSubcoleccion(res.docs[0].id,'redeemed',this.clienteServicio.id)
        console.log(filter.exists);
        if(filter.exists) Swal.fire('Upps..','El codigo ingreasdo ya fue redimido para este usuario','warning');
        else{
          this.tipoDeDescuento = "porcentual";
          this.descuentoServicio = 10;
          this.idCodigoReferido = res.docs[0].id;
          this.descuentoPlan = 10;
          if(this.tipoAgendamiento == 'Plan Mensual') this.agregarDescuentoPlan();
          else this.calcularprecioIndividual();
        }
      }
    })
  }



  /*********** DESINFECCION ***********/
  async valorPrecioDesinfeccion(event, tipo) {
    let name: string = ""
    if (tipo == 'area') {
      name = event
    } else {
      name = event.target.value;
    }
    let filter: any;
    if (tipo == "area") {
      filter = await this.frecuenciasDesinfeccion.filter((frecuencia) => frecuencia.name == name)[0]
      this.areasDesinfeccion = filter[tipo];
      console.log(this.areasDesinfeccion);
    } else if (tipo == 'precio') {
      filter = await this.areasDesinfeccion.filter((area) => area.name == name)[0];
      this.precioServicio = filter[tipo];
      this.gananciaServerServicio = filter["gananciaServer"];
      console.log(this.gananciaServerServicio);
    }
  }

  limitarDescuento() {
    if(this.tipoAgendamiento == "Plan Mensual"){
      if (this.tipoDeDescuento == 'porcentual' && this.descuentoPlan > 99) this.descuentoPlan = 0;
      else if(this.descuentoPlan === null) this.descuentoPlan = 0;
      this.agregarDescuentoPlan();
    }else{
      if (this.tipoDeDescuento == 'porcentual' && this.descuentoServicio > 99) this.descuentoServicio = 0;
      else if (this.descuentoServicio == null) this.descuentoServicio = 0;  
      
      this.calcularprecioIndividual()
    }
  }

  isWarranty(){
    if(this.metodoPagoServicio == "Garantia"){
      this.plazoPagoServicio = `Pago adelantado`
      this.estadoDelPagoServicio = "Pago"
      this.urlComprobanteServicio = "Garantia"
    }
  }

  verificarEstadoPago(plazoPago) {
    if (plazoPago == "pago adelantado") {
      if (this.tipoAgendamiento == "Plan Individual" || this.tipoAgendamiento == "Multiples servicios" || this.tipoAgendamiento == "Administrativo") {
        this.fechaLimitePagoServicio = moment(this.fechaServicio).subtract(1, "days").format("YYYY-MM-DD")
      } else {
        this.arrayPreInfoServiciosPlan = _.orderBy(this.arrayPreInfoServiciosPlan, ["fecha"], ["asc"]);
        let primeraFecha = this.arrayPreInfoServiciosPlan[0].fecha
        this.fechaLimitePagoServicio = moment(primeraFecha).subtract(1, "days").format("YYYY-MM-DD")
      }
      this.plazoPagoServicio = "Pago adelantado";
      this.estadoDelPagoServicio = "Pago"
      console.log(this.plazoPagoServicio, this.fechaLimitePagoServicio);
    } else {
      let a = parseInt(plazoPago)
      if (this.tipoAgendamiento == "Plan Individual" || this.tipoAgendamiento == "Multiples servicios" || this.tipoAgendamiento == "Administrativo") {
        this.fechaLimitePagoServicio = moment(this.fechaServicio).add(a, "days").format("YYYY-MM-DD")
      } else {
        this.arrayPreInfoServiciosPlan = _.orderBy(this.arrayPreInfoServiciosPlan, ["fecha"], ["asc"]);
        let primeraFecha = this.arrayPreInfoServiciosPlan[0].fecha;
        this.fechaLimitePagoServicio = moment(primeraFecha).add(a, "days").format("YYYY-MM-DD")
      }
      this.plazoPagoServicio = `A ${a} días`
      this.estadoDelPagoServicio = "Pendiente"
      this.urlComprobanteServicio = "Falta"
      console.log(this.plazoPagoServicio, this.fechaLimitePagoServicio);
    }
  }

  verCuponosDisponibles() {
    console.log('entro en verCuponosDisponibles()')
    if (this.cuponesDisponibles.length === 0) {
      this.cuponService.getCuponesActivos().then(res => {
        res.docs.forEach(data => {
          const cupon = data.data()
          console.log(cupon)
          const cuponRedimido = cupon.clientesArray.filter(cliente => cliente.id == this.clienteServicio.id)
          console.log(cuponRedimido)
          if (cuponRedimido.length === 0) {
            this.cuponesDisponibles.push(cupon)
          }
        })
      }).catch(err => {
        console.log('ocurrio un error al trar los cupones', err)
      })
    } else {
      console.log('CuponesYaRevisados')
    }
  }

  cuponSeleccionado(event) {
    console.log('Cupon usado', this.cuponesDisponibles[event.target.selectedIndex])
    this.cuponElegido = this.cuponesDisponibles[event.target.selectedIndex]
  }

  onUploadComprobante(event) {
    let file: any;
    let pictureName: any;
    let image = false;
    let nameImage: any;
    let fotoSeleccionada: any;
    console.log("files", event.target.files[0]);
    file = event.target.files[0];
    pictureName = Date.now() + file.name;
    image = true;
    if (event.target.files && file) {
      nameImage = file.name;
      let reader = new FileReader();
      reader.onload = (event: ProgressEvent) => {
        fotoSeleccionada = (<FileReader>event.target).result;
        console.log("foto seeccionada", fotoSeleccionada);
      };
      reader.readAsDataURL(event.target.files[0]);
    } else {
      console.log("no pasa el primer if");
    }
    if (file && pictureName) {
      this.servicioService.putPicture(pictureName, file)
        .then((value) => {
          console.log("value", value);
          if (value.state == "success") {
            this.servicioService.getURL(pictureName).subscribe((url) => {
              this.urlComprobanteServicio = url;
              console.log(url);
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log("no pasa el segundo if");
    }
    console.log(this.urlComprobanteServicio);
  }

  agendarServicioIndividual() {
    this.loadingAgendar = true;
    if (this.descuentoServicio > 99) {
      this.descuentoServicio = 0
    }
    let servicio: any = {
      asesor: this.usuarioEnSecion.displayName,
      ejecutivoCuenta: 'Leidy Carolina Barrero Briñez',
      categoria: this.categoriaServicio,
      checkList: this.taskListFinal,
      client: this.clienteServicio,
      comprobante: this.urlComprobanteServicio,
      creado: "admin",
      calificacion: 'falta',
      destination: this.direccionServicio,
      dia: this.diaServicio,
      direccion: this.direccionServicio.direccion,
      estado: this.tipoGestion == "server" ? "Pendiente" : "Orden de servicio",
      estadoPago: this.estadoDelPagoServicio,
      fecha: this.fechaServicio,
      fechaCreacion: moment().format("YYYY-MM-DD"),
      fechaLimitePago: this.fechaLimitePagoServicio,
      formaDePago: this.metodoPagoServicio,
      hora: `${this.horaInicioServicio}:00`,
      horario: this.horarioServicio,
      horasDeServicio: this.horasDeServicio,
      id: new Date().getTime(),
      idServer: this.tipoGestion == "server" ? this.serverServicio.id : "",
      // neighborhood:this.direccionServicio.neighborhood, //Ya esta en destination
      novedad: false,
      numFactura: this.numFacturaServicio,
      precio: this.tipoAgendamiento == 'Administrativo' ? 0 : this.precioServicio,
      descuento: this.tipoDeDescuento == 'porcentual' ? (this.precioPlan*this.descuentoServicio)/100 : this.descuentoServicio,
      recargo: this.recargoServicio,
      recargoTransporte: this.recargoTransporteServicio,
      razonRecargo: this.razonRecargo,
      referenciaPago: this.referenciaPagoServicio,
      total: this.tipoAgendamiento == 'Administrativo' ? 0 : this.final_service_price,
      server: this.tipoGestion == "server" ? this.serverServicio : "",
      servicio: this.tipoServicio,
      subCategoria: this.subCategoriaServicio,
      tipoServicio: this.tipoAgendamiento == 'Multiples servicios' ? 'Plan Individual' : this.tipoAgendamiento,
      estadoPagoServer: "Pendiente",
      userid: this.clienteServicio.id,
      subasta: this.tipoGestion == "server" ? false : true,
      metrosDesinfeccion: this.categoriaServicio == 'Desinfección' ? this.metrosDesinfeccionServicio : "No aplica",
      gananciaServer: this.gananciaServerServicio,
      responsablePostventa: this.usuarioEnSecion.displayName,
      gananciaServ: (this.precioServicio + this.recargoServicio) - this.gananciaServerServicio,
      bonoPuntualidad_App: true,
      posiblePlan: this.posiblePlan,
      requiereSegSoc: this.requiereSegSoc,
    }
    console.log("Info para agendar ",servicio)
    if(this.productList.length > 0){
      servicio.extras = this.productList;
      servicio.valorExtras = this.totalValueExtras;
    }

    if (this.tipoGestion == "server") {
      if (this.serverServicio.numeraria.estado === true && servicio.fecha === moment().add(1, 'days').format('YYYY-MM-DD')) {
        this.serverServicio.numeraria.estado === false
      }
    }

    if (this.clienteServicio.serversAgendadas == undefined) {
      this.clienteServicio.serversAgendadas = ['1']
    }

    const arrayServers = this.clienteServicio.serversAgendadas.filter(server => server == servicio.idServer)
    if (this.asignarPostVenta == true || arrayServers.length == 0) {
      servicio.estadoPostventa = "postventa pendiente";
      servicio.responsablePostventa = this.usuarioEnSecion.displayName;
      servicio.fechaHoraPostventa = moment(this.fechaServicio).add(1, 'days').format('YYYY-MM-DD');
    }

    this.servicioService.registerServicio(servicio).then(async res => {
      this.agregarPagoIndividual(servicio)
      if (this.cuponElegido != '') {
        this.actualizarCupon(this.clienteServicio)
      }
      if (servicio.client.rol === 'usuario lead') {
        servicio.client.rol = 'usuario'
        this.usuarioService.updateClient(servicio.client)
      }
      if (arrayServers.length == 0) {
        this.clienteServicio.serversAgendadas.push(servicio.idServer)
        this.actualizarServersCliente(this.clienteServicio)
      }
      if(this.idCodigoReferido  != ''){
        const data = {
          idUser: this.clienteServicio.id,
          date: moment().format('YYYY-MM-DD')
        }
        await this.codigoReferidoServerService.updateRedeemed(this.idCodigoReferido,data);
      }
      this.loadingAgendar = false;
      Swal.fire("Agendado", "El servicio a sido agendado", "success");
      if (this.tipoAgendamiento == "Multiples servicios") {
        this.numFacturaServicio = "";
        this.serverServicio = null;
        this.fechaServicio = "";
        this.horaInicioServicio =  "";
      } else {
        const element: HTMLElement = document.getElementById("botonSalir") as HTMLElement;
        element.click();
      }
      // this.enviarConfirmacion(servicio);
    })
  }

  actualizarCupon(info) {
    this.cuponElegido.clientesArray.push({
      id: info.id,
      nombre: `${info.name} ${info.apellido}`,

      telefono: info.telefono,
      documento: info.documento,
      fechaUso: moment().format('YYYY-MM-DD')
    })
    this.cuponService.updateCupon(this.cuponElegido)
  }

  /*########## COMENTARIOS CLIENTE #########*/
  AddComentario(tipo) {
    this.comentario.name = this.usuarioEnSecion.displayName;
    if (tipo == "server" && this.clienteServicio !== null) {
      this.ingresarComentarioLogistica = false;
      this.ingresarComentarioServer = true;
    } else if (tipo == "logistica" && this.clienteServicio !== null) {
      this.ingresarComentarioServer = false;
      this.ingresarComentarioLogistica = true;
    }
  }
  cancelarComentario() {
    this.ingresarComentarioLogistica = false;
    this.ingresarComentarioServer = false;
    this.comentario.comentario = '';
  }
  guardarComentario(tipo) {
    this.comentario.name = this.usuarioEnSecion.displayName;
    const ret: any = this.funcionesUsuario.comentariosCliente(
      tipo,
      this.clienteServicio,
      this.comentario
    );
    this.ingresarComentarioLogistica = false;
    this.ingresarComentarioServer = false;
    this.comentario = {
      name: "",
      comentario: "",
      fecha: this.today,
    };
  }
  /*########################################*/  

/*---------Tareas cliente--------*/
  async onSaveNewTask(){
    if(this.newTask.description.length > 5 ){
      let newTaskRef = JSON.stringify(this.newTask);
      await this.funcionesUsuario.tareasCliente(this.clienteServicio,newTaskRef);
      this.activeAddNewTask = false;
      this.clientTaskList.push(JSON.parse(newTaskRef));
    }
  }

  async onSaveNewTaskPlan(){
    if(this.newTask.description.length > 5 ){
      let newTaskRef = JSON.stringify(this.newTask);
      await this.funcionesUsuario.tareasCliente(this.clienteServicio,newTaskRef);
      this.activeAddNewTask = false;
      this.diasSelccionadoPlan.forEach(res=>res.taskList.push(JSON.parse(newTaskRef)))
      this.clientTaskList.push(JSON.parse(newTaskRef));
    }
  }

  onAddTaskToPlan(i){
    this.diasSelccionadoPlan[this.daySelectedTaskList].taskList[i].seleccionado = !this.diasSelccionadoPlan[this.daySelectedTaskList].taskList[i].seleccionado;
    if(this.diasSelccionadoPlan[this.daySelectedTaskList].taskList[i].seleccionado){
      this.diasSelccionadoPlan[this.daySelectedTaskList].taskListHours += parseInt(this.diasSelccionadoPlan[this.daySelectedTaskList].taskList[i].duration);
    }else{
      this.diasSelccionadoPlan[this.daySelectedTaskList].taskListHours -= parseInt(this.diasSelccionadoPlan[this.daySelectedTaskList].taskList[i].duration);
      this.diasSelccionadoPlan[this.daySelectedTaskList].taskListHours = 
        this.diasSelccionadoPlan[this.daySelectedTaskList].taskListHours = this.diasSelccionadoPlan[this.daySelectedTaskList].taskListHours < 0 
        ? 0 
        : this.diasSelccionadoPlan[this.daySelectedTaskList].taskListHours
    }
  }

  onAddTaskToService(i){
    if (!this.clientTaskList[i].seleccionado) {
      this.taskHours += parseInt(this.clientTaskList[i].duration);
      this.taskListFinal.push(this.clientTaskList[i]);
    } else {
      const index = this.taskListFinal.indexOf(this.clientTaskList[i]);
      if (index !== -1) {
        this.taskHours -= parseInt(this.taskListFinal[index].duration);
        this.taskHours  = this.taskHours < 0 ? 0 : this.taskHours
        this.taskListFinal.splice(index, 1);
      }
    }
    this.clientTaskList[i].seleccionado = !this.clientTaskList[i].seleccionado
    console.log(this.taskHours,this.taskListFinal)
  }

  changeTaskHours(){
    this.taskHours = 0;
    this.taskListFinal.map(res=>{
      this.taskHours += parseInt(res.duration);
    })
    console.log(this.taskHours);
  }

  changeTaskHoursPlan(){
    this.diasSelccionadoPlan[this.daySelectedTaskList].taskListHours = 0;
    this.diasSelccionadoPlan[this.daySelectedTaskList].taskList.map(res=>{
      this.diasSelccionadoPlan[this.daySelectedTaskList].taskListHours += parseInt(res.duration);
    })
    console.log(this.taskHours);
  }

  validateTaskList(): boolean{
    let isValid = true; 
    if(this.tipoAgendamiento == "Plan Mensual"){
      // console.log("A");
      const int_horasDeServicio = this.horasDeServicio;
      for(let i = 0; i < this.diasSelccionadoPlan.length;i++ ){
        const taskSelected =  this.diasSelccionadoPlan[i].taskList.filter(res=>res.seleccionado == true);
        // console.log( 'dias',i,this.diasSelccionadoPlan[i].taskListHours, int_horasDeServicio*60,"----", taskSelected.length ,(int_horasDeServicio - 1) )
        if(  this.diasSelccionadoPlan[i].taskListHours == int_horasDeServicio*60 || taskSelected.length == (int_horasDeServicio - 1) ){
          this.diasSelccionadoPlan[i].taskListAlert = '';
        }else{
          this.diasSelccionadoPlan[i].taskListAlert = 'La lista de tareas no es valida';
          isValid = false;
          // break;
        }
      }
      isValid = true;
    }else{
      const int_horasDeServicio = this.horasDeServicio;
      // console.log(this.taskHours, int_horasDeServicio*60 , this.taskListFinal.length , (int_horasDeServicio - 1))
      if( this.taskHours == int_horasDeServicio*60 || this.taskListFinal.length == (int_horasDeServicio - 1) ){
        isValid = true;
      }else{
        isValid = true;
        // isValid = false;
      }
      if(this.horaInicioServicio == "") isValid = false;
    }
    return isValid
  }

  selectedDayTaskList(i){
    this.diasSelccionadoPlan.map(res=>res.selected = false);
    this.diasSelccionadoPlan[i].selected = true;
    this.daySelectedTaskList = i;
    console.log(this.diasSelccionadoPlan,this.daySelectedTaskList);
  }

  createTaskListPlan(){
    return new Promise(resolve=>{
      let taskListFinal = [];
      for(let i = 0;i<this.diasSelccionadoPlan.length;i++){
        let array = this.diasSelccionadoPlan[i].taskList.filter(res=> res.seleccionado == true);
        console.log(array)
        taskListFinal.push({
          dia: this.diasSelccionadoPlan[i].diaInt,
          taskList: array
        })
      }
      return resolve(taskListFinal);
    })
  }

/* ----------------------------------- */

/*---------------- Productos---------------- */
  onSaveNewProduct(){
    this.totalValueExtras = 0;
    let newProductRef = JSON.stringify(this.newProduct);
    this.productList.push(JSON.parse(newProductRef));
    this.productList.forEach(product=>{
      this.totalValueExtras += product.value;
    });
    this.activeAddNewProduct = false;
    if(this.tipoAgendamiento == 'Plan Mensual') this.calcularPrecioPlan();
    else this.calcularprecioIndividual();
  }
/*-------------------------------------------*/

  contadorServiviciosCreados = 0;
  agendarServiciosDelPlan() {
    this.loaderAgendandoPlan = true;
    this.contadorServiviciosCreados = 0
    this.arrayPreInfoServiciosPlan.forEach(element => {
      console.log("datos del servicio",element)
      let servicio: any = {
        asesor: this.usuarioEnSecion.displayName,
        categoria: this.categoriaServicio,
        checkList: (this.diasSelccionadoPlan.filter(res=>res.diaInt == parseInt(moment(element.fecha).format("d")))[0].taskList).filter(res=>res.seleccionado == true),
        client: this.clienteServicio,
        comprobante: this.urlComprobanteServicio,
        creado: "admin",
        calificacion: 'falta',
        destination: this.direccionServicio,
        dia: element.dia,
        direccion: this.direccionServicio.direccion,
        estado: this.tipoGestion == "server" ? "Aceptado" : 'Orden de plan',
        estadoPago: this.estadoDelPagoServicio,
        fecha: element.fecha,
        fechaCreacion: moment().format("YYYY-MM-DD"),
        fechaLimitePago: this.fechaLimitePagoServicio,
        formaDePago: this.metodoPagoServicio,
        hora: element.hora + ":00",
        horario: element.horario,
        horasDeServicio: this.horasDeServicio,
        id: element.id,
        idServer: this.tipoGestion == "server" ? this.serverServicio.id : "",
        // neighborhood:this.direccionServicio.neighborhood, //Ya esta en destination
        novedad: false,
        numFactura: this.numFacturaServicio,
        precio: element.precio,
        recargo: element.recargo,
        recargoTransporte: element.recargoTransporte,
        descuento: element.descuento,
        total: element.precio + element.recargo + element.recargoTransporte - element.descuento,
        referenciaPago: this.referenciaPagoServicio,
        server: this.tipoGestion == "server" ? this.serverServicio : "",
        servicio: this.tipoServicio,
        subCategoria: this.subCategoriaServicio,
        // tipoPropiedad: this.direccionServicio.tipoPropiedad,
        tipoServicio: this.tipoAgendamiento,
        userid: this.clienteServicio.id,
        subasta: this.tipoGestion == "server" ? false : false,
        estadoPagoServer: "Pendiente",
        gananciaServer: element.gananciaServer,
        gananciaServ: (element.precio + element.recargo + element.recargoTransporte) - element.gananciaServer,
        bonoPuntualidad_App: true,
        aceptaRemplazos: this.aceptaRemplazos,
        requiereSegSoc: this.requiereSegSoc,
      }
      console.log("Datos del servicio que guarda", servicio)
      this.servicioService.registerServicio(servicio).then(res => {
        console.log("ServicioGuardado")
        this.contadorServiviciosCreados++
        if (this.contadorServiviciosCreados == this.arrayPreInfoServiciosPlan.length) {
          this.agendarPlan()
        }
        // Swal.fire("Agendado","El servicio a sido agendado","success");
      })
    });
  }

  async agendarPlan() {
    let plan: any = {
      idNotificacion: new Date().getTime(),
      asesor: this.usuarioEnSecion.displayName,
      cantidadReagendanientos: 0,
      cantidadServicios:  this.arrayPreInfoServiciosPlan.length,
      categoria: this.categoriaServicio,
      client: this.clienteServicio,
      comprobanteActual: this.urlComprobanteServicio,
      correoPorVencer: false,
      creado: 'admin',
      checkList: await this.createTaskListPlan(),
      destination: this.direccionServicio,
      direccion: this.direccionServicio.direccion,
      enviarCorreo: true,
      estado: this.tipoGestion == "server" ? "Activo" : "Orden de plan",
      estadoConfirmacion: "Aceptado",
      estadoPago: this.estadoDelPagoServicio,
      fechaCreacion: moment().format("YYYY-MM-DD"),
      fechaInicio: this.arrayPreInfoServiciosPlan[0].fecha,
      fechaLimitePago: this.fechaLimitePagoServicio,
      plazoDePago: this.plazoPagoServicio,
      fechaReagendamiento: moment().format("YYYY-MM-DD"),
      fechaVencimiento: this.arrayPreInfoServiciosPlan[this.arrayPreInfoServiciosPlan.length-1].fecha, // se ajusta aotmaticamentre al cargar la tabla de planes
      formaDePago: this.metodoPagoServicio,
      frecuencia: `${this.diasSelccionadoPlan.length} por semana`,
      horasDeServicio: this.horasDeServicio,
      horaDeInicio: this.horaInicioServicio,
      infoServicio: this.arrayPreInfoServiciosPlan,
      numeroFactura: this.numFacturaServicio,
      pagoDosCuotas: this.pagoDosCuotas == 'si' ? true : false,
      recurrente: "si",
      referenciaPago: this.referenciaPagoServicio,
      server: this.tipoGestion == "server" ? this.serverServicio : '',
      idServers: this.tipoGestion == "server" ? this.serverServicio.id : "",
      serverName: this.tipoGestion == "server" ? this.serverServicio : "Orden de servicio",
      servicio: this.tipoServicio,
      serviciosConsumidos: 0,
      serviciosRestantes: this.arrayPreInfoServiciosPlan.length,
      subCategoria: this.subCategoriaServicio,
      // tipoPropiedad: this.direccionServicio.tipoPropiedad,
      tipoServicio: "Plan Mensual",
      precio: this.precioPlan,
      descuento: this.tipoDeDescuento == 'porcentual' && this.descuentoPlan > 0 ? (this.precioPlan*this.descuentoPlan)/100 : this.descuentoPlan ,
      precioIndividual: this.precioPorServicio,
      recargo: this.recargoPlan * this.arrayPreInfoServiciosPlan.length,
      recargoTransporte: this.recargoTransportePlan * this.arrayPreInfoServiciosPlan.length,
      total: this.final_plan_price,
      userid: this.clienteServicio.id,
      usuario: `${this.clienteServicio.name} ${this.clienteServicio.apellido}`,
      totalServicios: this.arrayPreInfoServiciosPlan.length,
      diasDeServicio: [],
      aceptaRemplazos: this.aceptaRemplazos,
      idInfoPago: '',
    }
    if(this.productList.length > 0){
      plan.extras = this.productList;
      plan.valorExtras = this.totalValueExtras;
    }
    if (plan.destination.department === "Cundinamarca") {
      plan.asesor = 'Leidy Carolina Barrero Briñez';
    } else if (plan.destination.department === "Antioquia") {
      plan.asesor = 'Laura Rippe';
    }
    if (this.pagoDosCuotas == 'si') {
      plan.cuota = (plan.total / 2)
    }
    if (this.clienteServicio.serversAgendadas == undefined) {
      this.clienteServicio.serversAgendadas = ['1']
    }
    const arrayServers = this.clienteServicio.serversAgendadas.filter(server => server == plan.idServers)
    if (this.asignarPostVenta == true || arrayServers.length == 0) {
      plan.estadoPostventa = "postventa pendiente";
      plan.responsablePostventa = this.usuarioEnSecion.displayName;
      let a = Math.abs(moment(this.arrayPreInfoServiciosPlan[0].fecha).diff(this.arrayPreInfoServiciosPlan[this.arrayPreInfoServiciosPlan.length - 1].fecha, 'days'))
      plan.fechaPostventa = moment(this.arrayPreInfoServiciosPlan[0].fecha).add((a / 2), 'days').format("YYYY-MM-DD");
    }

    if (this.usuarioEnSecion.rol === 'ventas') {
      plan.asesor = this.usuarioEnSecion.displayName
    }
    let diasPlanRecurente = [];
    let diasPlan = [];

    for(let day of this.diasSelccionadoPlan){
      diasPlanRecurente.push(day.diaString);
      diasPlan.push({ dia: day.diaInt });
    }

    let planRecurrente = {
      id: "",
      horas: plan.horasDeServicio,
      clientId: plan.client.id,
      checkList: this.taskListFinal,
      clienteName: `${plan.client.name}  ${plan.client.apellido}`,
      horaInicio: this.arrayPreInfoServiciosPlan[0].hora,
      diasAgendado: diasPlanRecurente,
      fechaDeInicio: plan.fechaInicio,
      fechaDeVencimiento: null,
      numServicios: plan.cantidadServicios,
      horasDeServicio: plan.horasDeServicio,
      precioServicio: plan.precioIndividual,
      idServerAsignada: this.tipoGestion == 'server' ? plan.server.id : '',
      serverName: this.tipoGestion == 'server' ? `${plan.server.name}  ${plan.server.apellido}` : '',
    };
    
    plan.diasDeServicio = diasPlan;
    const idInfoPago = await this.crearPagoPlan(plan);
    plan.idInfoPago = idInfoPago;
    this.servicioService.registrarPlan(plan, planRecurrente).then(async (res) => {
      if (this.cuponElegido != '') {
        await this.actualizarCupon(this.clienteServicio)
      }
      if (this.tipoGestion == "server") {
        if (arrayServers.length == 0) {
          this.clienteServicio.serversAgendadas.push(plan.idServers)
        }
        await this.actualizarServersCliente(this.clienteServicio)
      }
      if(this.idCodigoReferido  != ''){
        const data = {
          idUser: this.clienteServicio.id,
          date: moment().format('YYYY-MM-DD')
        }
        await this.codigoReferidoServerService.updateRedeemed(this.idCodigoReferido,data);
      }
      this.loadingAgendar = false;
      Swal.fire("Agendado", "Has logrado crear un plan con exito", "success").then(res => {
        const element: HTMLElement = document.getElementById("botonSalir") as HTMLElement;
        element.click();
      })
    }).catch(error=>{
      this.servicioService.deletePagoPlan(idInfoPago);
      console.log("Erro al refgistrar el plan",error)
    })

  }

  // Funcion para guaradar en el cliente la nueva server que se le asigno
  actualizarServersCliente(cliente) {
    this.usuarioService.updateClient(cliente)
  }

  public agregarPagoIndividual(info) {
    let cobro: any = {
      id: info.id,
      horas: info.horasDeServicio,
      precio: info.precio,
      recargo: info.recargo,
      recargoTransporte: info.recargoTransporte,
      descuento: info.descuento,
      total: info.total,
      estadoDelPago: info.estadoPago,
      tipoServicio: info.tipoServicio,
      factura: '',
      comprobante: info.comprobante,
      numeroServicios: 1,
      idPlanOServicio: info.id,
      clientId: info.client.id,
      fechaAgendamiento: info.fecha,
    };
    console.log(cobro)
    this.servicioService.postPagoPlanUsuario(cobro);
  }

  public crearPagoPlan(info){
    return new Promise(async resolve=>{
      let cobro = {
        id: '',
        horas: info.horasDeServicio,
        precio: info.precio,
        recargo: info.recargo,
        recargoTransporte: info.recargoTransporte,
        descuento: info.descuento,
        total: info.total,
        tipoServicio: info.tipoServicio,
        factura: "",
        comprobante: info.comprobanteActual,
        estadoDelPago: info.estadoPago,
        numeroServicios: info.cantidadServicios,
        idPlanOServicio: '',
        verifidadoEnBanco: false,
        fechaAgendamiento: info.fechaReagendamiento,
        fechaInicio: info.fechaInicio,
        fechaFin: info.fechaVencimiento,
        clientId: info.client.id
      } 
      const id = await this.servicioService.addPagoPlanUsuario(cobro);
      resolve(id)
    })
  }

  // public agregarMultiplesPagosAlHistorial(info) {
  //   console.log("Entro en agregarMultiplesPagosAlHistorial")
  //   let idPlan = this.servicioService.idPlanAgendado;

  //   this.mesesSelccionadoPlan.forEach((mesInfo: any, index: number) => {
  //     let cobro = {
  //       id: mesInfo.id,
  //       horas: info.horasDeServicio,
  //       precio: mesInfo.precio,
  //       recargo: mesInfo.recargo,
  //       recargoTransporte: mesInfo.recargoTransporte,
  //       descuento: info.descuento,
  //       total: (mesInfo.precio + mesInfo.recargo + mesInfo.recargoTransporte) - info.descuento,
  //       tipoServicio: info.tipoServicio,
  //       factura: "",
  //       comprobante: index == 0 ? info.comprobanteActual : "",
  //       estadoDelPago: index == 0 ? info.estadoPago : "Pendiente",
  //       numeroServicios: mesInfo.servicosDelMes,
  //       idPlanOServicio: idPlan,
  //       verifidadoEnBanco: false,
  //       fechaAgendamiento: info.fechaReagendamiento,
  //       fechaInicio: mesInfo.fechaInicio,
  //       fechaFin: mesInfo.fechaFin,
  //       clientId: info.client.id
  //     }
  //     console.log(cobro)
  //     this.servicioService.postPagoPlanUsuario(cobro);
  //   })
  // } 

  /******************************************************************************/

  enviarConfirmacion(event) {
    let notificacionConfirmacion = event;
    notificacionConfirmacion.idTemplate = "d-d19f6afa0d294d3ea4e9019932b8cfb2";
    notificacionConfirmacion.fechaParseCliente = moment(event.fecha).format("dddd DD [de] MMMM [de] YYYY");
    console.log(notificacionConfirmacion.fechaParseCliente);
    const requestCorreoSendgrid = firebase2
      .functions()
      .httpsCallable("requestCorreoAspirantes");
    requestCorreoSendgrid({
      name: notificacionConfirmacion.client.name,
      correo: notificacionConfirmacion.client.email,
      telefono: notificacionConfirmacion.client.telefono,
      servicioCompleto: notificacionConfirmacion,
      idTemplate: notificacionConfirmacion.idTemplate,
    }).then((result) => {
      console.log(result.data);
    });
  }

  onCustom(event) {
    // console.log(event)
    this.servicioVisualizado = event;
    this.verMdlInfoSevicio = false
    setTimeout(() => {
      this.verMdlInfoSevicio = true
    }, 200)
  }

  public serviciosExcel: any = []
  async exportarParaEcxel() {
    this.generalLoader = true;
    // this.servicioService.getServiciosSemanaActual('2022-05-01','2023-08-01').then(async res=>{
    //   console.log(res.docs.length)
    // })
    let promises = this.allServices.map(async data => {
      let _server: any = {};
      if(data.idServer != "" && data.idServer != "Pendiente"){
        const res = (await this.serverService.detail(data.idServer))
        if(res.exists) _server = res.data();
      }
      console.log(data.id,_server.cedula);
      return {
        // id: data.client !== undefined ? data.client.id : ' No se encontro cliente',
        clase: data.tipoServicio,
        fecha: data.fecha,
        horasDeServicio: isNaN(data.horasDeServicio) ? 6 : data.horasDeServicio,
        estado: data.estado,
        cliente: data.nombreCliente !== undefined ? data.nombreCliente : 'Sin nombre',
        total: data.total,
        categiriaServicio: data.categoria,
        cubCategoriaServicio: data.subCategoria,
        hora: data.hora,
        creado: data.creado,
        fechaCreacion: data.fechaCreacion,
        departamento: typeof data.destination !== 'undefined' &&
          typeof data.destination !== 'undefined' &&
          typeof data.destination.department !== 'undefined'
          ? data.destination.department
          : "no",
        server: data.nombreServer,
        documentoServer: _server.cedula != undefined ? _server.cedula : "Falta",
        tipoContrato: _server.tipoContrato != undefined ? _server.tipoContrato : "Falta",
        horaio: _server.diponibilidadHoraria != undefined ? _server.diponibilidadHoraria : "Falta",
        "Fecha creacion": _server.fechaIngreso != undefined ? _server.fechaIngreso : 'Falta ',
        'Inicio contrato': _server.fechaInicioContrato != undefined ? _server.fechaInicioContrato : "",
        'Desactivacion': _server.fechaDesactivacion != undefined ? _server.fechaDesactivacion : "Falta",
      }
    });
    this.serviciosExcel = await Promise.all(promises);
    this.servicioService.exportAsExcelFile(this.serviciosExcel, "Servicio");
    this.generalLoader = false;
  }

  eliminarNotificaciones() {
    this.servicioService.deleteNotisActulizar();
  }

  verificarServersSolicitadas() {
    this.listaClientes.forEach(async element => {
      console.log(element.serversAgendadas)
      if (element.serversAgendadas == undefined) {
        // console.log('1',element.serversAgendadas)
        element.serversAgendadas = ['1']
        this.usuarioService.updateClient(element).then(() => {
          console.log('5')
        })
        // await this.verSiervicios(element)
      } else {
      }
    });
  }

  verSiervicios(cliente) {
    console.log('1')
    let count = 0;
    this.servicioService.getByUser(cliente.id).then(async res => {
      console.log('2-----', res.docs.length)
      if (res.docs.length > 0) {
        res.docs.forEach(async data => {
          let servicio = data.data()
          const a = cliente.serversAgendadas.filter(server => server == servicio.idServer)
          // console.log(a)
          if (a.length == 0) {
            cliente.serversAgendadas.push(servicio.idServer)
          }
          count++
          if (count == res.docs.length) {
            await this.guardarCliente(cliente)
          }
        })
      } else {
        await this.guardarCliente(cliente)
      }
    })
  }

  guardarCliente(cliente) {
    // console.log(cliente)
    this.usuarioService.updateClient(cliente).then(() => {
      console.log('5')
    })
  }

  validateRecargo() {
    if (this.recargoServicio > 30000) {
      this.recargoServicio = 0;
    }
    if (this.recargoTransporteServicio > 30000) {
      this.recargoTransporteServicio = 0;
    }
  }

  validateRecargoPlan() {
    if (this.recargoPlan > 30000) {
      this.recargoPlan = 0;
    }
    if (this.recargoTransportePlan > 30000) {
      this.recargoTransportePlan = 0;
    }
  }

  public diasSelccionadoPlan = [];
  public mesesSelccionadoPlan = [];
  public arrayMeses = [];
  public fechasPlan = [];

  calcularMesesSiguentes() {
    this.arrayMeses = [];
    for (let i = 0; i < 7; i++) {
      const m = moment().add(i, 'months').format('MMMM')
      const mI = moment().add(i, 'months').format('M')
      this.arrayMeses.push({
        name: m,
        index: mI
      })
    }
  }

  seleccionarDiasPlan(i) {
    console.log(i)
    let index = this.diasSelccionadoPlan.findIndex(res => res.diaInt == i)
    console.log("Index dias", index)
    let diaString = ""
    if (i == 0) {
      diaString = "domingo"
    } else if (i == 1) {
      diaString = "lunes"
    } else if (i == 2) {
      diaString = "martes"
    } else if (i == 3) {
      diaString = "miercoles"
    } else if (i == 4) {
      diaString = "jueves"
    } else if (i == 5) {
      diaString = "viernes"
    } else if (i == 6) {
      diaString = "sabado"
    }
    if (index == -1) {
      let a = JSON.stringify(this.clientTaskList)
      this.diasSelccionadoPlan.push({
        hora: 6,
        diaInt: i,
        diaString: diaString,
        taskList: JSON.parse(a),
        selected:  this.diasSelccionadoPlan.length == 0 ? true : false,
        taskListHours: 0,
        taskListAlert: '',
      })
    } else {
      this.diasSelccionadoPlan.splice(index, 1);
    }
    this.mesesSelccionadoPlan = [];
    // console.log(this.diasSelccionadoPlan)
  }

  seleccionarMesesPlan(indexMes, indexArray) {
    let index = this.mesesSelccionadoPlan.findIndex(res => res.mes == indexMes)
    // console.log("Index planes",index,indexArray)

    if (index == -1) {
      this.mesesSelccionadoPlan.push({
        mes: indexMes,
        indexArray: indexArray,
        precio: 0,
        total: 0,
        id: new Date().getTime(),
        precioIndividual: 0,
        fechaInicio: "",
        fechaFin: "",
        serviciosVinculados: [],
      })
    } else {
      this.mesesSelccionadoPlan.splice(index, 1)
    }
    // console.log(this.mesesSelccionadoPlan)
  }

  cssDiaPlanes(i,type): string {

    const result = type == 1 ? this.diasSelccionadoPlan.findIndex(res => res.diaInt == i) : (this.diasSelccionadoPlan[i].selected == true ? 1 : -1);
    if (result == -1) {
      return "div-diasPlan"
    } else {
      return "div-diasPlanActive" 
    }
  }

  cssMesPlanes(i): string {
    // console.log("Propiuedadpara  ngClase",i)
    const result = this.mesesSelccionadoPlan.findIndex(res => res.mes == i)
    if (result == -1) {
      return "div-diasPlan"
    } else {
      return "div-diasPlanActive"
    }
  }

  //TODO REVISAR EL USO DE ESTAS FUNCIONES
  seleccionarHoraDiasPLan(i, event) {
    const info = event.target.value;
    const index = this.diasSelccionadoPlan.findIndex(res => res.diaInt == i)
    this.diasSelccionadoPlan[index].hora = info
  }

  generarFechasPlan() {
    let contadorDias = 0;
    this.listaServers = [];
    this.arrayPreInfoServiciosPlan = [];
    this.mesesSelccionadoPlan.forEach(async (res: any) => {
      console.log("mes--", res.mes, "indexMes--", res.indexArray)
      let mesCalculado = moment().format("YYYY-M-D")
      if (res.indexArray > 0) {
        mesCalculado = moment().add(res.indexArray, "month").startOf("month").format("YYYY-M-D")
      }
      console.log("Mes a calcular", mesCalculado)
      // this.arrayPreInfoServiciosPlan.push(res.mes);
      for (let dia of this.diasSelccionadoPlan) {
        const nextDate = new Date(mesCalculado);
        console.log("Paso1-------------", nextDate);
        let a = 0;
        nextDate.setDate(nextDate.getDate() + (dia.diaInt + 7 - nextDate.getDay()) % 7);
        console.log("Paso2", nextDate);
        for (let i = 0; i < 5; i++) {
          let fecha = "";
          let fechaProb = "";
          console.log(i, a, "Antes de sumar", nextDate, "-------------")
          // fecha.setDate(nextDate.getDate() + ((i+a)*7));
          fecha = moment(nextDate).add((i + a) * 7, 'days').format("YYYY-MM-DD")
          // fechaProb = moment(nextDate).add((i+a)*7,'days').format("YYYY-MM-DD");
          console.log("despues de la suma", fecha);
          console.log("despues de la suma Fecha prob", fechaProb);
          if (moment(fecha).format("YYYY-MM-DD") == moment().format("YYYY-MM-DD")) {
            fecha = moment(fecha).add(7, 'days').format("YYYY-MM-DD")
            // fecha.setDate(fecha.getDate() + 7);
            // console.log("Aumento");
            a = 1;
          }
          let filterFestivos = this.arrayFestivos.filter(res =>{
            console.log(res.fecha,moment(fecha).format("YYYY-MM-DD"))
            return res.fecha == moment(fecha).format("YYYY-MM-DD")
          } );
          console.log("Filtro festivos", filterFestivos, moment(fecha).format("YYYY-MM-DD"));
          console.log(parseInt(moment(fecha).format("M")), res.mes)
          if (parseInt(moment(fecha).format("M")) == res.mes) {
            let idServicio = new Date().getTime()
            console.log(this.horasDeServicio)
            if(this.horasDeServicio == 8 && this.diasSelccionadoPlan.length >= 5 ) {
              this.precioPorServicio = this.infoValoresPrecio.precioTiempoCompleto;
            }else if(this.horasDeServicio == 4 && this.diasSelccionadoPlan.length >= 5 && this.horaInicioServicio >= '14'){
              this.precioPorServicio = this.infoValoresPrecio.precioTiempoCompleto - this.infoValoresPrecio.descuentoTardes;
            }else if(this.horasDeServicio == 4 && this.diasSelccionadoPlan.length >= 5 && this.horaInicioServicio < '14'){
              this.precioPorServicio = this.infoValoresPrecio.precioTiempoCompleto;
            }
            let infoServicio = {
              fecha: moment(fecha).format("YYYY-MM-DD"),
              id: idServicio,
              idServicios: idServicio,
              hora: this.horaInicioServicio,
              idServer: "",
              dia: dia.diaString,
              horario: this.horarioServicio,
              precio: this.precioPorServicio,
              recargoDominical: dia.diaInt == 0 ? 15000 : filterFestivos.length > 0 ? 15000 : 0,
              recargo: (dia.diaInt == 0 ? 15000 : filterFestivos.length > 0 ? 15000 : 0),
              recargoTransporte: 0,
              descuento: 0,
              total: 0,
              gananciaServer: this.gananciaServerServicio,
              estado: "Pendiente",
              razonRecargo: dia.diaInt == 0 ? 'Recargo dominical y festivo' : filterFestivos.length > 0 ? 'Recargo dominical y festivo' : 'Sin recargo'
            };
            this.arrayPreInfoServiciosPlan.push(infoServicio)
          }
        }
        contadorDias++
        if (contadorDias == (this.diasSelccionadoPlan.length * this.mesesSelccionadoPlan.length)) {
          console.log("Array de servicios plan", this.arrayPreInfoServiciosPlan)
          this.arrayPreInfoServiciosPlan = _.orderBy(this.arrayPreInfoServiciosPlan, ["id"], ["asc"])
          this.horaInicioServicio = this.arrayPreInfoServiciosPlan[0].hora;

          this.validarIdServiciosPlan();
        }
      }
    })
  }

  validarIdServiciosPlan() {
    let i = 1;
    let count = 0
    let idsEnUso = [];
    this.arrayPreInfoServiciosPlan.forEach(info => {
      // console.log(info.id)
      const filtroId = idsEnUso.findIndex(res => res == info.id)
      console.log(filtroId, info.id)
      if (filtroId == -1) {
        idsEnUso.push(info.id)
      } else {
        info.id = info.id + i;
        info.idServicios = info.id
        i++
        idsEnUso.push(info.id)
      }
      count++

      if (count == this.arrayPreInfoServiciosPlan.length) {
        console.log(this.arrayPreInfoServiciosPlan);
        this.flippedPlanes = true;
        this.arrayPreInfoServiciosPlan = _.orderBy(this.arrayPreInfoServiciosPlan, ["fecha"], ["asc"])
        // console.log(this.arrayPreInfoServiciosPlan);
        this.calcularPrecioPlan();
      }
    })
  }

  eliminarServicioDeAgendamiento(index) {
    console.log(index);
    this.arrayPreInfoServiciosPlan.splice(index, 1)
    this.calcularPrecioPlan();
  }


  agregarRecargoPlan() {
    console.log( this.arrayPreInfoServiciosPlan);
    this.arrayPreInfoServiciosPlan.map(res => {
      res.recargo = res.recargoDominical + this.recargoPlan;
      res.recargoTransporte = this.recargoTransportePlan
    })
    this.calcularPrecioPlan();
  }
  agregarDescuentoPlan() {
    console.log(this.arrayPreInfoServiciosPlan);
    const descuento = this.tipoDeDescuento == 'porcentual' && this.descuentoPlan > 0 ? (this.precioPlan*this.descuentoPlan)/100 : this.descuentoPlan 
    this.arrayPreInfoServiciosPlan.map(res => {
      res.descuento = descuento > 0 ? descuento/this.arrayPreInfoServiciosPlan.length : descuento;
      console.log(res.descuento)
    })
    this.calcularPrecioPlan();
  }

  calcularPrecioPlan(){
    let total_surcharge = 0;
    let total_transportation_surcharge = 0;
    let total_service_price = 0;
    const services = _.orderBy(this.arrayPreInfoServiciosPlan,['fecha'],['asc']);
    for(let service of services){
      total_surcharge += service.recargo;
      total_transportation_surcharge += service.recargoTransporte;
    }
    this.precioPlan = this.precioPorServicio * this.arrayPreInfoServiciosPlan.length;
    console.log( this.tipoDeDescuento == 'porcentual' && this.descuentoPlan > 0, this.tipoDeDescuento , this.descuentoPlan)
    let descuento = this.tipoDeDescuento == 'porcentual' && this.descuentoPlan > 0 ? ((this.precioPlan*this.descuentoPlan)/100) : this.descuentoPlan;
    console.log(this.precioPlan,descuento,this.descuentoPlan);
    this.final_plan_price = (this.precioPlan + total_surcharge + total_transportation_surcharge - descuento )+ this.totalValueExtras;
    this.editarRecargoPlan = false;
  }

  async filtrarServers() {
    this.loadingAgendar = true;
    const info = {
      cliente: { blackList: this.clienteServicio.blackList ? this.clienteServicio.blackList : [] },
      destination: this.direccionServicio,
      inicio: this.tipoAgendamiento == "Plan Mensual" ? this.horaInicioServicio : this.horaInicioServicio,
      duracion: this.horasDeServicio,
      fechas: this.tipoAgendamiento == "Plan Mensual" ? this.arrayPreInfoServiciosPlan : [{ fecha: this.fechaServicio, id: '' }],
      dias: {},
      idPlan: '',
      servicio: this.subCategoriaServicio
    }
    info.dias = await this.calcularDiasServicios(info.fechas)
    const infoValidada = await this.convertirNaNyNullAString(info);

    this.servBackendService.post('api/serv/get_servers_avalible', infoValidada).subscribe(res => {
      console.log('Res api/serv/get_servers_avalible', res);
      if (res.success) {
        const data =  _.orderBy(res.data, [
          'filtroObstructor',
          (item) => {
            switch (item.estado) {
              case 'Directa':
                return 0;
              case 'Sepernumeraria':
                return 1;
              case '':
                return 2;
              default:
                return 3; // Manejo de caso inesperado
            }
          },
        ], "asc");
        this.listaServers = data;
        this.copiaListaServers = data;
        this.filteredOptions = data
        this.loadingAgendar = false;
        console.log(this.tipoAgendamiento)
      } else {
        this.loadingAgendar = false;
        Swal.fire('Error', 'Ocurrio un problema porfavor intentalo de nuevo', 'warning')
      }

    })
  }

  convertirNaNyNullAString(obj: any): any {
    for (const clave in obj) {
      if (typeof obj[clave] === 'object' && obj[clave] !== null) {
        obj[clave] = this.convertirNaNyNullAString(obj[clave]); // Llamada recursiva para objetos anidados
      } else if (isNaN(obj[clave]) || obj[clave] === null) {
        obj[clave] = String(obj[clave]);
      }
    }
    return obj;
  }

  filtroSeguridadSocial(event) {
    console.log(event.target.checked)
    let serversConSeguridad: any;
    if (event.target.checked) {
      if (this.tipoAgendamiento === 'Plan mensual') {
        serversConSeguridad = this.listaServers.filter(server => server.fechaVencimientoSeguridadSocial > moment().format('YYYY-MM-DD') && server.estadoSeguridadSocial == 'aprobado');
        // serversConSeguridad = _.orderBy(serversConSeguridad, ['porcentajeOcupacionSemana'],["asc"])
      } else {
        serversConSeguridad = this.listaServers.filter(server => server.fechaVencimientoSeguridadSocial > this.fechaServicio);
      }
      this.listaServers = serversConSeguridad;
    } else {
      this.listaServers = this.copiaListaServers;
    }
  }

  calcularDiasServicios(fechas) {
    return new Promise(resolve => {

      let diasServicio = []
      fechas.forEach(element => {
        let numDia = moment(element.fecha).day();
        let dia = "";
        if (numDia == 0) {
          dia = "domingo";
        } else if (numDia == 1) {
          dia = "lunes";
        } else if (numDia == 2) {
          dia = "martes";
        } else if (numDia == 3) {
          dia = "miercoles";
        } else if (numDia == 4) {
          dia = "jueves";
        } else if (numDia == 5) {
          dia = "viernes";
        } else if (numDia == 6) {
          dia = "sabado";
        }

        let diaAgregado = diasServicio.filter((day) => day == dia);
        if (diaAgregado.length == 0) diasServicio.push(dia);
      })
      resolve(diasServicio)
    })
  }

  agregatVariablesBonos() {
    this.servicioService.getInDateRange('2023-08-17', '2023-08-31').then(res => {
      console.log(res.docs.length)
      res.docs.forEach(async element => {
        const servicio = element.data();
        console.log(servicio.server.name, servicio.server.apellido, servicio.id)
        const novedades: any = await this.getNovedadesServico(servicio.id)
        servicio.bonoPuntualidad_App = novedades.puntualidad;
        this.servicioService.updateServicio(servicio).then(() => { console.log('A') })
      });
    })
  }

  getNovedadesServico(id) {
    return new Promise(resolve => {
      this.metricasService.getNovedadesServicio(id).then(res => {
        let bonos = {
          puntualidad: true,
          seguridad: true
        }
        if (res.docs.length > 0) {
          res.docs.forEach((data, index) => {
            if (data.data().tipo == 'Servicio sin seguridad social') {
              bonos.seguridad = false;
              console.log('sin bono seguridad', bonos.seguridad, id)
            } else if (data.data().tipo == 'Puntualidad' || data.data().tipo == 'No uso la app') {
              bonos.puntualidad = false;
              console.log('sin bono Puntualidad', bonos.puntualidad, id)
            }
            if (index == res.docs.length - 1) {
              resolve(bonos)
            }
          })
        } else {
          console.log('Con bonos')
          resolve(bonos)
        }
      })
    })
  }
  
  // Funcion que filtra en la tabla los registros con datos que cincidan con lo escrito
  applyFilter(filterValue: string) {
    this.tableDataSource.filter = filterValue.trim().toLowerCase();
    console.log(this.tableDataSource)
  }

  changeTable(table) {
    switch (table) {
      case 'orden':
        this.tableDataSource = new MatTableDataSource(this.arrayServiciosOrdenes);
        this.tableDataSource.paginator = this.paginator;
        this.tableDataSource.sort = this.sort;
        break;
      case 'pendiente':
        this.tableDataSource = new MatTableDataSource(this.arrayServiciosPendientes);
        this.tableDataSource.paginator = this.paginator;
        this.tableDataSource.sort = this.sort;
        break;
      case 'aceptado':
        this.tableDataSource = new MatTableDataSource(this.arrayServiciosAceptados);
        this.tableDataSource.paginator = this.paginator;
        this.tableDataSource.sort = this.sort;
        break;
      case 'confirmado':
        this.tableDataSource = new MatTableDataSource(this.arrayServiciosConfirmados);
        this.tableDataSource.paginator = this.paginator;
        this.tableDataSource.sort = this.sort;
        break;
      case 'enProceso':
        this.tableDataSource = new MatTableDataSource(this.arrayServiciosEnProceso);
        this.tableDataSource.paginator = this.paginator;
        this.tableDataSource.sort = this.sort;
        break;
      case 'cancelado':
        this.tableDataSource = new MatTableDataSource(this.arrayServiciosCancelados);
        this.tableDataSource.paginator = this.paginator;
        this.tableDataSource.sort = this.sort;
        break;
      case 'terminado':
        this.tableDataSource = new MatTableDataSource(this.arrayServiciosTerminados);
        this.tableDataSource.paginator = this.paginator;
        this.tableDataSource.sort = this.sort;
        break;
      case 'paraPrestar':
        this.tableDataSource = new MatTableDataSource(this.arrayServciosParaPrestar);
        this.tableDataSource.paginator = this.paginator;
        this.tableDataSource.sort = this.sort;
        break;
      case 'todos':
        this.tableDataSource = new MatTableDataSource(this.arrayServicios);
        this.tableDataSource.paginator = this.paginator;
        this.tableDataSource.sort = this.sort;
        break;
    }
  }

  colorEstado(element): String {
    if (element) {
      const a = this.helper.ExtractColor(element.estado, this.listColorService)
      return a
    } else {
      return 'white'
    }
  }
  colorCliente(element): String {
    if (element.client.tieneServiciosTerminados == undefined) {
      return 'primary'
    } else {
      return 'white'
    }
  }

  ajustarDireccion() {
    for (let i = 0; i < this.allServices.length; i++) {
      const servicio = this.allServices[i]
      const cliente = this.listaClientes.filter(res => res.id == servicio.userid)[0];
      const n_direccion = cliente.direccionesArray.filter(res => res.direccion == servicio.destination.direccion)[0];
      console.log(n_direccion, servicio.destination);
      if (n_direccion) {
        firebase.firestore().collection('servicios').doc(`${servicio.id}`).update({ destination: n_direccion }).then(() => console.log('U'))
      }
    }
  }

  toggleAutocompleteList(){
    this.activeListOptionsCliente = false;
    this.activeListOptionServers = false;
  }

  onClickAutocomplete(event: Event,type: any): void {
    event.stopPropagation();
    this.toggleAutocompleteList();
    this.updateStateAutocomplete(type,true,null);
  }

  onInputAutocomplete(event: any,key: any,type: string){
    let value = event.target.value ? event.target.value : ''
    switch (type){
      case 'nombreCliente': 
        this.filteredOptions = this.listaClientes.filter((res: any) =>
          res[key].toLowerCase().includes(value.toLowerCase())
        );
        break;
      case 'nombreServer':
        this.filteredOptions = this.listaServers.filter((res: any) =>
          res[key].toLowerCase().includes(value.toLowerCase())
        );
        break;
    }
    this.updateStateAutocomplete(type,true,null);
  }

  onSelectAutocomplete(value: any,type: string): void {
    this.updateStateAutocomplete(type,false,value);
  }

  async updateStateAutocomplete(type: string,state: boolean,value: any){
    console.log('updateStateAutocomplete',type)
    switch (type){
      case 'nombreCliente':
        this.activeListOptionsCliente = state;
        if(value != null && this.tipoCliente == "serv" ) {
          this.clienteServicio = value;
          this.clientTaskList = this.clienteServicio.taskList ? await this.clienteServicio.taskList.map(res=>{ return{...res,seleccionado: false}}) : [];
          console.log(this.clientTaskList);
        }else if(value != null && this.tipoCliente == "airbnb" ) {
          this.selectClientAirbnb(value);
        }
        break;
      case 'nombreServer':
        this.activeListOptionServers = state;
        if (value != null && value.filtroObstructor == '') {
          console.log(value);
          this.serverSeleccionada = value;
          this.serverServicio = await this.getServerSeleccionada(value);
        } else {
          console.log('Bloqueada', value)
        }
        break;
    }
  }

  async selectClientAirbnb(airbnb){
    this.clienteServicio = airbnb;
    this.direccionServicio = airbnb.location;
    this.tipoServicio = "Airbnb";
    this.categoriaServicio = "aseo y servicios generales";
    this.subCategoriaServicio = "aseo airbnb";
    this.plazoPagoServicio = `Pago adelantado`;
    this.estadoDelPagoServicio = "Pago";
    this.tipoGestion = "server";
    this.tipoAgendamiento = "Plan Individual";
    this.metodoPagoServicio = "airbnb";
    this.referenciaPagoServicio = "falta";
    this.urlComprobanteServicio = "Falta"
    this.plazoPago = "15"
    console.log(this.subCategoriaServicio);
    const query_service = await this.priceService.getspecificField('servicio',this.subCategoriaServicio);
    const query_prices = await query_service.docs[0].ref.collection('valores').get();
    this.allPriceList = query_prices.docs.map(res=>{
      return {
        id: res.id,
        ref: res.ref,
        ...res.data() 
      };
    });
    this.priceList = this.allPriceList.filter(res=>res.tipoAgendamiento == "expres");
    const a = document.getElementById("elegirGestion") as HTMLSelectElement;
    a.disabled = false;

    if(this.clienteServicio.assignedServer != ""){
      console.log(this.clienteServicio);
      this.serverServicio = await this.getServerSeleccionada({id: this.clienteServicio.assignedServer});
      console.log("Server consultada",this.serverServicio);
      this.serverSeleccionada = this.serverServicio;
      this.serverSeleccionada.nombreServer = `${this.serverServicio.name} ${this.serverServicio.apellido}`;
      console.log(this.serverSeleccionada)
    }
  }

  getServerSeleccionada(info) {
    return new Promise(async resolve => {
      console.log(info);
      const server = (await this.serverService.detail(info.id)).data();
      console.log(server)
      resolve(server)
    })
  }

  calcWidthGridDias() {
    return `calc(100% / ${this.diasSelccionadoPlan.length})`;
  }


  async exportarClietesSinPlan(){

    let arrayExports = [];
    this.planService.getPlanesOneParam('frecuencia',"1 por semana").then(async res=>{
      console.log('Planes consultados', res.size)
      let promises = res.docs.map(async data=>{
        const res1 = (await this.planService.getPlanesByClient((data.data().userid)))
        const res2 = (await this.planService.getPlanesclientFrecuencia("1 por semana",(data.data().userid)))
        if(res1.size - res2.size > 0 ) arrayExports.push(data.data().client);
        return 1;
      });
      const resultPromise = await Promise.all(promises);
      console.log('planeclientes para export',arrayExports);
      this.servicioService.exportAsExcelFile(arrayExports, "Servicio");
    })
    console.log('a')

    // let clietnesSinPlan = [];
    // console.log('Servcicios individuales',this.allExpresServices.length);
    // let promises = this.allExpresServices.map(async servicio => {
    //   const res = (await   this.planService.getPlanesByClient(servicio.userid))
    //   if(res.size) clietnesSinPlan.push(servicio.client)
    //   return 1;
    // });
    // this.serviciosExcel = await Promise.all(promises);
    // this.servicioService.exportAsExcelFile(clietnesSinPlan, "Servicio");
  }



}